import * as yup from "yup";

const websiteUrlPattern =
  /https?:\/\/(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?/;

const validFileExtensions = {
  image: ["jpg", "png", "jpeg", "svg", "webp"],
};

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType]?.indexOf(fileName?.split(".").pop()) > -1
  );
}

export const validationSchemaLogin = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .required("Please enter email address"),
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters long")
    .max(16, "Password must be at most 16 characters long"),
});

export const validationSchemaForgotpassword = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email ")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email "
    )
    .required("Please enter email address"),
});

export const validationSchemaOtp = yup.object().shape({
  otp: yup.string().required("Please enter otp"),
});

export const validationSchemaResetPassword = yup.object().shape({
  newPassword: yup
    .string()
    .required("Please enter new password")
    .min(8, "Password must be 8 characters long")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password")
    .oneOf([yup.ref("newPassword"), null], "New password and confirm password should be the same"),
});

export const validationSchemaChangepassword = yup.object().shape({
  oldPassword: yup.string().required("Please enter old password"),
  newPassword: yup
    .string()
    .required("Please enter new password")
    .min(8, "Password must be 8 characters long")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const validationSchemaYearOfSahay = yup.object().shape({
  fromYear: yup.string().required("This field is requried"),
  toYear: yup.string().required("This field is requried"),
});

export const validationSchemaGenreEdit = yup.object().shape({
  genreName: yup
    .string()
    .required("Please enter genre name")
    .max(15, "Genre must be at most 15 characters"),
});

export const validationSchemaGameAddEdit = yup.object().shape({
  gameName: yup
    .string()
    .required("Please enter game name")
    .max(15, "Game must be at most 15 characters"),
  genreId: yup.string().required("Please enter genre name"),
  gameLogo: yup
    .mixed()
    .required("Please upload game logo")
    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg, or .svg files",
      (value) =>
        typeof value !== "object"
          ? isValidFileType(value, "image")
          : isValidFileType(value && value?.name?.toLowerCase(), "image")
    ),
});

export const validationSchemaLockAddEdit = yup.object().shape({
  stakeSpn: yup
    .string()
    .required("Please enter stake spn")
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  LockDay: yup.string().required("Please enter days"),

  LockRate: yup.string().required("Please enter apy rate"),
  LockAmount: yup.string().required("Please enter maximum staking amount"),
  title: yup
    .string()
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .required("Please enter title")
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )

    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  description: yup
    .string()
    .required("Please enter description")
    .max(50, "Description must be at most 50 characters"),
  stakeFees: yup.string().required("Please enter early unstake fees"),
});
export const validationSchemaLockEdit = yup.object().shape({
  stakeSpn: yup
    .string()
    .required("Please enter stake spn")
    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  title: yup
    .string()
    .required("Please enter title")
    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  LockDay: yup.string().required("Please enter days"),
  LockRate: yup.string().required("Please enter apy rate"),
  LockAmount: yup.string().required("Please enter maximum staking amount"),

  description: yup
    .string()
    .required("Please enter description")
    .max(50, "Description must be at most 50 characters"),
  stakeFees: yup.string().required("Please enter early unstake fees"),
});

export const validationSchemaRewardsAddEdit = yup.object().shape({
  RewardsType: yup
    .string()
    .required("Please enter rewards type")
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  StartTokenID: yup.string().required("Please enter start token"),
  EndTokenID: yup.string().required("Please enter end token "),
  MetaData: yup
    .string()
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .required("Please enter meta data "),
  description: yup
    .string()
    .required("Please enter description")
    .max(50, "Description must be at most 50 characters"),
  gameLogo: yup
    .mixed()
    .required(
      "Please upload rewards image (only JPG, JPEG, and PNG files are allowed)"
    )
    .test(
      "fileFormat",
      "Invalid file format (only JPG, JPEG, and PNG files are allowed)",
      (value) => {
        return value && ["image/jpeg", "image/png"].includes(value.type);
      }
    )
    .test(
      "fileExtension",
      "Invalid file extension (only JPG, JPEG, and PNG files are allowed)",
      (value) => {
        return value && /\.(jpg|jpeg|png)$/i.test(value.name);
      }
    ),
});

export const validationSchemaRewardsEdit = yup.object().shape({
  phaseId: yup.string().required("Please phase id"),
  RewardsType: yup
    .string()
    .required("Please enter rewards type")
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .matches(/^(?!\s)[A-Za-z\s]+$/, "Please enter only alphabets"),
  StartTokenID: yup.string().required("Please enter start token "),
  EndTokenID: yup.string().required("Please enter end token"),
  MetaData: yup
    .string()
    .test(
      "no-leading-space",
      "Starting space not allowed",
      (value) => !/^ /.test(value)
    )
    .required("Please enter meta data "),
  description: yup
    .string()
    .required("Please enter description")
    .max(50, "Description must be at most 50 characters"),
  gameLogo: yup
    .mixed()
    .required(
      "Please upload rewards image (only JPG, JPEG, and PNG files are allowed)"
    ),
});

export const validationSchemaBannerAddEdit = yup.object().shape({
  image: yup
    .mixed()
    .required("Please upload banner image")
    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg, or .svg files",
      (value) =>
        typeof value !== "object"
          ? isValidFileType(value, "image")
          : isValidFileType(value && value?.name?.toLowerCase(), "image")
    ),
  bannerName: yup.string().required("Please enter banner name"),
  websiteLink: yup
    .string()
    .matches(websiteUrlPattern, "Please enter valid website link")
    .required("Please enter website link"),
});
