import React from "react";
import Index from "../../Index";

export default function NoDataFound(props) {
  return (
    <>
      <Index.TableRow>
        <Index.TableCell align="center" colSpan={props.colSpan}>
          <div className="not-found-text">
            <img src={Index.noDataFound} />
          </div>
        </Index.TableCell>
      </Index.TableRow>
    </>
  );
}
