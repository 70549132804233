import dashboard from "./images/svg/dashboard.svg";
import logo from "./images/svg/logo.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import cancel from "./images/svg/cancel.svg";
import righticon from "./images/svg/righticon.svg";
import righticonblue from "./images/svg/righticonblue.svg";

import sidebar1 from "./images/svg/sidebar1.svg";
import sidebar2 from "./images/svg/sidebar2.svg";
import sidebar3 from "./images/svg/sidebar3.svg";
import sidebar4 from "./images/svg/sidebar4.svg";
import sidebar5 from "./images/svg/sidebar5.svg";
import sidebar6 from "./images/svg/sidebar6.svg";
import sidebar7 from "./images/svg/sidebar7.svg";
import sidebar8 from "./images/svg/sidebar8.svg";
import bell from "./images/svg/bell.svg";
import search from "./images/svg/search.svg";
import collapse from "./images/svg/collapse.svg";
import headprofile from "./images/svg/head-profile.svg";
import closeold from "./images/svg/closeold.svg";
import gray_dashboard from "./images/svg/grayDashboard.svg";
import white_dashboard from "./images/svg/whiteDashboard.svg";
import grey_user from "./images/svg/grayUser.svg";
import white_user from "./images/svg/whiteUser.svg";
import gray_games from "./images/svg/grayGameController.svg";
import white_games from "./images/svg/whiteGameController.svg";
import white_ads_banners from "./images/svg/whiteAdvertisement.svg";
import gray_ads_banners from "./images/svg/grayAdvertisement.svg";
import white_tickets from "./images/svg/whiteTickets.svg";
import gray_tickets from "./images/svg/grayTickets.svg";
import whiteCheck from "./images/svg/whiteCheck.svg";
import whiteInsertCoin from "./images/svg/whiteInsertCoin.svg";
import whiteGrowth from "./images/svg/whiteGrowth.svg";
import add from "./images/svg/add.svg";
import whiteDraw from "./images/svg/whiteDraw.svg";
import whiteDelete from "./images/svg/delete.svg";
import grayDown from "./images/svg/grayDown.svg";
import gray_star from "./images/svg/grayStar.svg";
import cms from "./images/svg/cms.svg";
import stake from "./images/svg/stake.svg";
import wallet from "./images/svg/wallet.svg";
import admin from "./images/svg/admin.svg";
import lockPeriod from "./images/svg/lockPeriod.svg";
import rewardsNft from "./images/svg/rewardsNft.svg";
import pause from "./images/svg/pause.svg";

const Svg = {
  headprofile,
  wallet,
  bell,
  search,
  dashboard,
  gray_star,
  collapse,
  logo,
  filter,
  close,
  cancel,
  righticon,
  righticonblue,

  sidebar1,
  sidebar2,
  sidebar3,
  sidebar4,
  sidebar5,
  sidebar6,
  sidebar7,
  sidebar8,
  closeold,
  gray_dashboard,
  white_dashboard,
  grey_user,
  white_user,
  gray_games,
  white_games,
  white_ads_banners,
  gray_ads_banners,
  white_tickets,
  gray_tickets,
  whiteCheck,
  whiteInsertCoin,
  whiteGrowth,
  add,
  whiteDraw,
  whiteDelete,
  grayDown,
  cms,
  stake,
  admin,
  lockPeriod,
  rewardsNft,
  pause,
};

export default Svg;
