import Svg from "../assets/Svg";
import Png from "../assets/Png";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";

import {
  validationSchemaLogin,
  validationSchemaForgotpassword,
  validationSchemaOtp,
  validationSchemaResetPassword,
  validationSchemaChangepassword,
  validationSchemaYearOfSahay,
  validationSchemaGenreEdit,
  validationSchemaBannerAddEdit,
  validationSchemaRewardsEdit,
  validationSchemaLockAddEdit,
  validationSchemaLockEdit,
  validationSchemaRewardsAddEdit,
  validationSchemaGameAddEdit,
} from "../validation/Validation";
import DataService, { IMAGE_URL_ENDPOINT } from "../config/DataService";
import { Api } from "../config/Api";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";
import { useEffect } from "react";

const PagesIndex = {
  Formik,
  Svg,
  Png,
  CKEditor,
  ClassicEditor,
  toast,
  ToastContainer,
  Outlet,
  Link,
  useEffect,
  useDispatch,
  useNavigate,
  Navigate,
  useLocation,
  useSelector,
  axios,
  validationSchemaLogin,
  validationSchemaLockEdit,
  validationSchemaGenreEdit,
  validationSchemaRewardsEdit,
  validationSchemaRewardsAddEdit,
  validationSchemaForgotpassword,
  validationSchemaResetPassword,
  validationSchemaLockAddEdit,
  validationSchemaChangepassword,
  validationSchemaOtp,
  validationSchemaGameAddEdit,
  validationSchemaYearOfSahay,
  validationSchemaBannerAddEdit,
  DataService,
  IMAGE_URL_ENDPOINT,
  Api,
  dayjs,
};

export default PagesIndex;
