import React, { useState } from "react";
import "../games/addgames.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../lockperiod/addlockperiod.css";
import { useAccount } from "wagmi";
import {  ethers } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { DugoutObj } from "../../../../Connectivity/ContractObject";


function AddLockPeriod() {
  const navigate = PagesIndex.useNavigate();
  const signer = useEthersSigner();
  const account = useAccount();
  const [loading, setLoading] = useState(false);
  const dayData = PagesIndex.useSelector((state) => state.admin.lockList);
  const [existingDays, setExistingDays] = useState(
    dayData.map((item) => item.day)
  );
  const [fieldErrors, setFieldErrors] = useState("");

  let initialValues = {
    LockDay: "",
    LockRate: "",
    LockAmount: "",
    description: "",
    stakeFees: "",
    stakeSpn: "",
    title: "",
  };

  const addEditLockCall = (urlEncoded) => { 
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_LOCK_PERIOD, urlEncoded)
    .then((res) => {
        if (res?.status ===  201) {
          PagesIndex.toast.success(res.data.message);
          navigate("/admin/lock-period");
        }
      })
      .catch((err) => {
        setLoading(false)
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const lockDay = async (
    numDays,
    interest,
    maxmumStakingAmount,
    earlyUnstakeFees,
    urlEncoded
  ) => {
    try {
      const dugout = await DugoutObj(signer);
      let maxmumstaking = ethers.utils.parseEther(
        maxmumStakingAmount.toString()
      );
      let addlockday = await dugout.addLockPeriods(
        numDays,
        interest,
        maxmumstaking,
        earlyUnstakeFees,
      );
      await addlockday.wait()
      addEditLockCall(urlEncoded);
    } catch (error) {
      setLoading(false)
      if (error?.error?.data?.message != undefined) {
        const err=error?.error?.data?.message?.split(":")
        PagesIndex.toast.error(err[err?.length - 1]);
        // PagesIndex.toast.error(error?.error?.data?.message);
      }
    }
  };

  const handleLockEditForm = async (values) => {
    setLoading(true)
    if (account?.address) {
      const urlEncoded = {
        "EarlyUnstakeFees": values?.stakeFees,
        "stakeSpn": values?.stakeSpn,
        "day": values?.LockDay,
        "apyrate": values?.LockRate,
        "maximumStakingAmount": values?.LockAmount,
        "title": values?.title,
        "description": values?.description
      };
      await lockDay(
        values?.LockDay,
        values?.LockRate,
        values?.LockAmount,
        values?.stakeFees,
        urlEncoded
      );
    } else {
      setLoading(false)
      connectionAlert();
    }
  };

  const connectionAlert = () => {
    PagesIndex.toast.error("Please connect Metamask");
  };
  return (
    <>
      <Index.Box className="add-games-main add-lock-period-main">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Add Lock Period
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Link to="/admin/lock-period" className="cus-link-btn">
              <Index.PrimaryButton
                btnLabel="Back"
                className="primary-btn add-game-btn"
              />
            </Index.Link>
          </Index.Box>
        </Index.Box>
        {loading ? (<>
          <Index.loader />
               </>):(
        <Index.Box className="game-upload-wrapper">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleLockEditForm}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaLockAddEdit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              resetForm,
            }) => (
              <Index.Box
                className="game-upload-body"
                component="form"
                onSubmit={handleSubmit}
              >
                <Index.Box className="game-flex">
                  <Index.Box className="game-upload-name">
                    <Index.Box className="admin-dashboad-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Stake SPN
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="stakeSpn"
                                  name="stakeSpn"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const inputValue = e?.target?.value?.replace(/[^A-Za-z ]/g, '');
                                    setFieldValue("stakeSpn", inputValue);
                                  }}
                                  // onChange={handleChange}
                                  value={values.stakeSpn}
                                  className="form-control"
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 32,
                                    },
                                  }}
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.stakeSpn && touched.stakeSpn
                                    ? errors.stakeSpn
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Days
                              </Index.FormHelperText>

                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="LockDay"
                                  name="LockDay"
                                  onBlur={handleBlur}
                                  value={values?.LockDay}
                                  className="form-control"
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue !== "0") {
                                      setFieldValue(
                                        "LockDay",
                                        inputValue.slice(0, 3)
                                      );
                                      if (existingDays.includes(inputValue)) {
                                    
                                        setFieldErrors(
                                          " This days already exists"
                                        );
                                      } else {
                                        setFieldErrors("");
                                      }
                                    }
                                  }}
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.LockDay && touched.LockDay
                                    ? errors.LockDay
                                    : null}
                                  {fieldErrors ? fieldErrors : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                APY rate <span> (%)</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="LockRate"
                                  name="LockRate"
                                  onBlur={handleBlur}
   
                                  value={values.LockRate}
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue !== "0" && Number(inputValue) < 101) {
                                      setFieldValue(
                                        "LockRate",
                                        inputValue.slice(0, 3)
                                      );
                                    }
                                  }}
                                  className="form-control"
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.LockRate && touched.LockRate
                                    ? errors.LockRate
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Early Unstake Fees<span> (%)</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="stakeFees"
                                  name="stakeFees"
                                  onBlur={handleBlur}
                                  className="form-control"
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue !== "0") {
                                      setFieldValue(
                                        "stakeFees",
                                        inputValue.slice(0, 3)
                                      );
                                    }
                                  }}
                                  value={values.stakeFees}
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.stakeFees && touched.stakeFees
                                    ? errors.stakeFees
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Maximum Staking Amount
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="LockAmount"
                                  name="LockAmount"
                                  onBlur={handleBlur}
                                  value={values.LockAmount}
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue !== "0") {
                                      setFieldValue(
                                        "LockAmount",
                                        inputValue.slice(0, 10)
                                      );
                                    }
                                  }}
                                  className="form-control"
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.LockAmount && touched.LockAmount
                                    ? errors.LockAmount
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="game-title">
                              Title
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="title"
                                name="title"
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  const inputValue = e?.target?.value?.replace(/[^A-Za-z ]/g, '');
                                  setFieldValue("title", inputValue);
                                }}
                                value={values?.title}
                                className="form-control"
                                InputProps={{
                                  inputProps: {
                                    maxLength: 100,
                                  },
                                }}
                              />
                              <Index.FormHelperText
                                error
                                className="error-text"
                              >
                                {errors.title && touched.title
                                  ? errors.title
                                  : null}
                              </Index.FormHelperText>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="game-title"
                          >
                            Description
                          </Index.Typography>

                          <Index.Box
                            className="form-group "
                            style={{ marginTop: "12px" }}
                          >
                            <PagesIndex.CKEditor
            
                              editor={PagesIndex.ClassicEditor}
                              data={values.description}
                              name="description"
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("description", data);
                            
                              }}
                            />

                            <Index.FormHelperText error className="error-text">
                              {errors.description && touched.description
                                ? errors.description
                                : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-game-btn-sec">
                  <Index.PrimaryButton
                    btnLabel={
                    "Add lock period"
                    }
                    className="primary-btn add-game-btn"
                    type="submit"
                  />
                </Index.Box>
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
        )}
      </Index.Box>
    </>
  );
}

export default AddLockPeriod;
