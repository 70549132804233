import profileimg from "./images/png/profileimg.png";
import arrow from "./images/png/arrow.png";

import card1 from "./images/png/card1.png";
import card2 from "./images/png/card2.png";
import card3 from "./images/png/card3.png";
import card4 from "./images/png/card4.png";
import invoice from "./images/png/invoice.png";
import invoice2 from "./images/png/invoice2.png";

import sahay_log from "./images/png/shay_logo.png";
import userlogo from "./images/png/user-logo.png";
import logo from "./images/png/logo.png";
import dugoutsidelogo from "./images/png/dugoutsidelogo.png";
import gray_dashboard from "./images/png/gray_dashboard.png";
import grey_user from "./images/png/grey_user.png";
import grey_games from "./images/png/grey_games.png";
import grey_ads_banners from "./images/png/grey_ads_banners.png";
import grey_tickets from "./images/png/grey_tickets.png";
import white_dashboard from "./images/png/white_dashboard.png";
import white_user from "./images/png/white_user.png";
import white_games from "./images/png/white_games.png";
import white_ads_banners from "./images/png/white_ads_banners.png";
import white_tickets from "./images/png/white_tickets.png";
import login_logo from "./images/png/login_logo.png";
import activeUser from "./images/png/whiteActiveUser.png";
import coinIc from "./images/png/coinIc.png";
import whiteCoinPurchase from "./images/png/whiteCoinPurchase.png";
import whiteRevenue from "./images/png/whiteRevenue.png";
import authImg from "./images/png/authImg.png";
import profile1 from "./images/png/profile1.png";
import plusShadow from "./images/png/plusShadow.png";
import tabImg from "./images/png/tabImg.png";
import logoIc from "./images/png/logoIc.png";
import logoText from "./images/png/logoText.png";

import noDataFound from "./images/png/no data.png";
import noData from "./images/png/noData.png";
import heroImg from "./images/png/heroImg.png";
import sportzCardImg from "./images/png/sportzCardImg.png";
import stakeSNP from "./images/png/stakeSNP.png";
import fbIc from "./images/png/fbIc.png";
import instaIc from "./images/png/instaIc.png";
import twitter from "./images/png/twitter.png";
import telegram from "./images/png/telegram.png";
import linkedin from "./images/png/linkedin.png";
import seeklogo from "./images/png/seeklogo.png";
import discord from "./images/png/discord.png";
import reddit from "./images/png/reddit.png";
import MetaMask from "./images/png/MetaMask.png";
const Png = {
  userlogo,
  sahay_log,

  invoice,
  invoice2,
  card1,
  card2,
  card3,
  card4,

  arrow,
  profileimg,

  logo,
  dugoutsidelogo,
  noDataFound,
  gray_dashboard,
  grey_user,
  grey_games,
  grey_tickets,
  grey_ads_banners,
  white_dashboard,
  white_user,
  white_games,
  white_tickets,
  white_ads_banners,
  login_logo,
  activeUser,
  coinIc,
  whiteCoinPurchase,
  whiteRevenue,
  authImg,
  profile1,
  plusShadow,
  tabImg,
  logoIc,
  logoText,
  noData,
  heroImg,
  sportzCardImg,
  stakeSNP,
  fbIc,
  instaIc,
  twitter,
  telegram,
  linkedin,
  seeklogo,
  discord,
  reddit,
  MetaMask,
};

export default Png;
