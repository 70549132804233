import React from "react";
import "../games/addgames.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function AddGenres() {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();

  const initialValues = {
    genreName: state?.item ? state?.item?.name : "",
  };

  const handleGenereEditForm = (values) => {
    const urlEncoded = new URLSearchParams();
    if (state?.item?._id) {
      urlEncoded.append("id", state?.item?._id);
    }
    urlEncoded.append("name", values?.genreName);

    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_GENRE, urlEncoded)
      .then((res) => {
        if (res?.data) {
          PagesIndex.toast.success(res.data.message);
          navigate("/admin/genres");
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="add-games-main add-genres-main">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            {state.mode} Genres
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Link to="/admin/genres" className="cus-link-btn">
              <Index.PrimaryButton
                btnLabel="Back"
                className="primary-btn add-game-btn"
              />
            </Index.Link>
          </Index.Box>
        </Index.Box>
        <Index.Box className="game-upload-wrapper">
          <Index.Box className="game-upload-header">
            {state.mode} Genre
          </Index.Box>

          <PagesIndex.Formik
            onSubmit={handleGenereEditForm}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaGenreEdit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
            }) => (
              <Index.Box
                className="game-upload-body"
                onSubmit={handleSubmit}
                component="form"
              >
                <Index.Box className="game-flex">
                  <Index.Box className="game-upload-name">
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="game-title">
                        Genre Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="genreName"
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 30 }}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          onFocus={handleFocus}
                          value={values.genreName}
                          onChange={handleChange}
                          error={
                            errors.genreName && touched.genreName ? true : false
                          }
                          helperText={
                            errors.genreName && touched.genreName
                              ? errors.genreName
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-game-btn-sec">
                  <Index.PrimaryButton
                    btnLabel={`${state.mode} Genre`}
                    className="primary-btn add-game-btn"
                    type="submit"
                  />
                </Index.Box>
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddGenres;
