import React from "react";
import PagesIndex from "../../container/PagesIndex";

const PublicLayout = ({ children }) => {
  const location = PagesIndex.useLocation();
  const isAdminLoggedIn = PagesIndex.useSelector((state) => state.admin.token);

  return !isAdminLoggedIn ? (
    <PagesIndex.Outlet />
  ) : (
    <PagesIndex.Navigate
      to="/admin/dashboard"
      state={{ from: location }}
      replace={true}
    />
  );
};

export default PublicLayout;
