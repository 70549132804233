import Index from "../../../Index";
import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import { useDispatch } from "react-redux";

import { getAdminUserList } from "../../../../redux/features/AddService";
import "./sahaytypemasterlist.responsive.css";
import {
  clearAllData,
  clearSearchResults,
  selectSearchResults,
  setAllData,
  setSearchResults,
} from "../../../../redux/features/adminslice/AdminSlice";
import { useLocation } from "react-router-dom";

const UserManagelist = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  let [currentRows, setCurrentRows] = React.useState([]);
  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [selecetedRecords, setSelectedRecords] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();

  const { state } = PagesIndex.useLocation();

  const { getAdminGameUserList } = PagesIndex.useSelector(
    (state) => state.admin
  );


  let currentData = PagesIndex.useSelector(selectSearchResults);

  useEffect(() => {
    setTableData(currentData);
  }, [currentData]);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const itemsPerPage = 10;

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = tableData?.slice(startIndex, endIndex);
    setCurrentRows(displayedItems);
  }, [tableData, page]);

  const fetchListData = () => {
    dispatch(getAdminUserList()).then((res) => {
      if (res?.payload?.status == 200) {

        dispatch(setSearchResults(res?.payload?.data));
        dispatch(setAllData(res?.payload?.data));
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    dispatch(clearAllData());
    fetchListData();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSwitchToggle = (userId) => {
    const data = {
      userId: userId,
    };

    PagesIndex.DataService.post(PagesIndex.Api.USER_ACTIVE_DEACTIVE, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handleDeleteUser = (data) => {
    const payload = {
      userIds: data,
    };
    PagesIndex.DataService.post(PagesIndex.Api.USER_DELETE, payload)
      .then((res) => {
        if (res?.data?.status === 200) {
          fetchListData();
          setSelected([]);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handleAllCheckDelete = () => {
    handleDeleteUser(selected);
  };
  const handlecheckDelete = () => {
    handleDeleteUser(selected);
  };


  const handleSelect = (itemId) => {
    if (selected?.includes(itemId)) {
      setSelected(selected?.filter((id) => id !== itemId));
    } else {
      setSelected([...selected, itemId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
    } else {
      setSelected(currentRows?.map((item) => item._id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selected?.length === currentRows?.length && currentRows?.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selected, currentRows]);


  return (
    <>
      <Index.Box className="title-header">
        <Index.Box className="title-header-flex res-title-header-flex">
          <Index.Box className="main-title">
            <Index.Typography variant="p" component="p" className="head-title">
              {state == "total_users" ? "Total Users" : "Users List"}
            </Index.Typography>
          </Index.Box>
          <Index.Box
            className={`d-flex align-items-center res-set-search ${
              selected?.length > 0 ? "" : "btn_optional"
            }`}
          >
            <Index.Box className="common-button blue-button res-blue-button">
              {selected?.length > 1 ||
              selected?.length === currentRows?.length ? (
                <Index.Button
                  onClick={() => handlecheckDelete()}
                  variant="contained"
                >
                  Delete All Selected
                </Index.Button>
              ) : selected?.length ? (
                <Index.Button
                  onClick={() => handlecheckDelete()}
                  variant="contained"
                >
                  Delete Selected
                </Index.Button>
              ) : null}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="sahay-table cus-table-wrapper user-list-table">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table user-list-res"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">
                      <Index.Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        className="cus-table-checkbox"
                        variant="th"
                        align="center"
                      />
                    </Index.TableCell>
                    <Index.TableCell align="left">No.</Index.TableCell>
                    <Index.TableCell align="left">User ID</Index.TableCell>
                    <Index.TableCell align="left">Name</Index.TableCell>
                    <Index.TableCell align="left">
                      E-Mail Address
                    </Index.TableCell>
                    <Index.TableCell align="left">Via Social</Index.TableCell>
                    <Index.TableCell align="left">
                      Mobile Number
                    </Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody className="table-body">
                    <Index.TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        align="center"
                        colSpan={10}
                      >
                        <Index.loader />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length > 0 ? (
                      currentRows?.map((item, index, row) => (
                        <Index.TableRow key={item.id}>
                          <Index.TableCell>
                            <Index.Checkbox
                              variant="td"
                              scope="row"
                              className="cus-table-checkbox"
                              align="center"
                              checked={selected?.includes(item?._id)}
                              onChange={() => handleSelect(item?._id)}
                            />
                          </Index.TableCell>
                          <Index.TableCell align="left">
                          {(page - 1) * itemsPerPage + index + 1}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.userId}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.name}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.email}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {" "}
                            {!item.viaSocial ? item.viaSocial : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {!item.mobile == 0 ? item.mobile : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {PagesIndex.dayjs(item?.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.isActive === true ? (
                              <Index.Button
                                variant="contained"
                                className="table-action-btn active-status"
                              >
                                Active
                              </Index.Button>
                            ) : (
                              <Index.Button
                                variant="contained"
                                className="table-action-btn inactive-status"
                              >
                                Inactive
                              </Index.Button>
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {/* {item.created} */}
                            <Index.Switch
                              defaultChecked={item?.isActive}
                              onClick={() => handleSwitchToggle(item._id)}
                            ></Index.Switch>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.NoDataFound colSpan={10} />
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Pagination
                count={count}
                align="left"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Index.Stack>
          </Index.Box> */}
        </Index.Box>
        <Index.Box className="pagination-design flex-start">
          <Index.Stack spacing={2}>
            <Index.Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
            />
          </Index.Stack>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserManagelist;
