import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  getGamesList,
  getRewardsList,
} from "../../../../redux/features/AddService";
import {
  STATUSES,
  clearAllData,
  clearSearchResults,
  selectSearchResults,
  setAllData,
  setSearchResults,
} from "../../../../redux/features/adminslice/AdminSlice";
import "../lockperiod/lockperiod.css";
import "../rewardsnft/rewardsnft.css";
import { useAccount } from "wagmi";
import { Signer, ethers } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { DugoutObj } from "../../../../Connectivity/ContractObject";

function RewardsNFT() {
  let [currentRows, setCurrentRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const signer = useEthersSigner();
  const account = useAccount();
  let currentData = PagesIndex.useSelector(selectSearchResults);
  // console.log(currentData,"currentData"); 

  PagesIndex.useEffect(() => {
    setTableData(currentData);
  }, [currentData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const itemsPerPage = 10;

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  PagesIndex.useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = tableData?.slice(startIndex, endIndex);
    setCurrentRows(displayedItems);
  }, [tableData, page]);

  const fetchListData = () => {
    dispatch(getRewardsList()).then(async (res) => {
      if (res?.payload?.status == 200) {
        setTableData(res?.payload?.data);
        dispatch(setAllData(res?.payload?.data));
        dispatch(setSearchResults(res?.payload?.data));
        await res.payload.data;
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }else{
        setLoading(false)
      }                                                                                                                                                                                                                                                                                                                                            
    }).catch((error)=>{
      setLoading(false);
    });
  };
  PagesIndex.useEffect(() => {
    setLoading(true);
    dispatch(clearSearchResults());
    dispatch(clearAllData());
    fetchListData();
  }, []);

  const handleAddEdit = (mode, item) => {
    const editData = {
      item: mode === "Edit" ? item : null,
      mode: mode,
    };
    navigate("/admin/add-rewards-nft", { state: editData });
  };

  const handleEdit = (mode, item) => {
    const editData = {
      item: mode === "Edit" ? item : null,
      mode: mode,
    };
    navigate("/admin/edit-rewards-nft", { state: editData });
  };
  const deleteRewards = (data) => {
    const payload = {
      phasedetailIds: data.id,
    };
    PagesIndex.DataService.post(PagesIndex.Api.DELETE_REWARD_NFT, payload).then(
      (res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
          setSelected([]);
        }
      }
    ).catch((err) => {
      // setLoading(false);
      PagesIndex.toast.error(err?.response?.data?.message);
    });
  };
  const removephaseId = async (_phaseid, data) => {
    setLoading(true);
    try {
      const dugout = await DugoutObj(signer);
      let removephasid = await dugout.removePhase(_phaseid);
      await removephasid.wait();
      deleteRewards(data);
    } catch (error) {
      if (error?.error?.data?.message != undefined) {
        const err=error?.error?.data?.message?.split(":")
        PagesIndex.toast.error(err[err?.length - 1]);
      }
      setLoading(false);
    }
  };

  const handleDeleteUser = async (data) => {
    await removephaseId(phaseId, data);
  };

  PagesIndex.useEffect(() => {
    if (selected?.length === currentRows?.length && currentRows?.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selected, currentRows]);

  const [DeleteId, setDeleteId] = useState();
  const [phaseId, setPhaseId] = useState();
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (id, phaseId) => {
    setPhaseId(phaseId);
    const newId = { id };
    setDeleteId(newId);
    setOpenDelete(true);
  };
  const handleDeleteSingleItem = () => {
    handleDeleteUser(DeleteId);
    handleCloseDelete();
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const connectionAlert = () => {
    PagesIndex.toast.error("Please connect Metamask");
  };
  return (
    <>
      <Index.Box className="main-dashboard">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Rewards NFT
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Link to={"/admin/add-rewards-nft"}>
              <Index.PrimaryButton
                btnLabel="Add Rewards NFT"
                imgSrc={PagesIndex.Svg.add}
                className="primary-btn add-game-btn"
                onClick={() => {
                  handleAddEdit("add");
                }}
              />
            </Index.Link>

            <Index.Box className="common-button blue-button res-blue-button"></Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box className="sahay-table cus-table-wrapper">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main barge-table reward-nft-table"
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell align="left">Phase Id</Index.TableCell>
                      <Index.TableCell align="left">
                        {" "}
                        Rewards Type
                      </Index.TableCell>
                      <Index.TableCell align="left">
                        Start Token
                      </Index.TableCell>
                      <Index.TableCell align="left">End Token</Index.TableCell>
                      <Index.TableCell align="left">Meta data</Index.TableCell>
                      <Index.TableCell align="left">Action </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  {loading ? (
                    <Index.TableBody className="table-body">
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <Index.TableCell
                          variant="td"
                          scope="row"
                          className="table-td"
                          align="center"
                          colSpan={7}
                        >
                          <Index.loader />
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  ) : (
                    <Index.TableBody className="table-body">
                      {currentRows?.length > 0 ? (
                        currentRows?.map((item, index, row) => (
                          <Index.TableRow
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell align="left">
                              {item?.phaseId}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.rewardtype}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.starttokenid}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.endtokenid}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="meta-link"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {item?.metadata}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Box className="gray-btn-main">
                                <Index.Box
                                  className="gray-btn"
                                  onClick={() => {
                                    handleEdit("Edit", item);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteDraw}
                                    className="action-btn-img"
                                    alt="user"
                                  />
                                </Index.Box>
                                {/* </Index.Link> */}
                                <Index.Button
                                  className="gray-btn"
                                  onClick={() =>
                                    handleOpenDelete(item?._id, item?.phaseId)
                                  }
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteDelete}
                                    className="action-btn-img"
                                    alt="user"
                                  />
                                </Index.Button>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.NoDataFound colSpan={7} />
                      )}
                    </Index.TableBody>
                  )}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-design flex-start">
            <Index.Stack spacing={2}>
              <Index.Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.whiteDelete} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these record?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={() =>
                account?.address ? handleDeleteSingleItem() : connectionAlert()
              }
            >
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default RewardsNFT;
