import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";

const PrivayPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  let intialValues = {
    description: privacyPolicy ? privacyPolicy?.description : "",
  };

  const handleFormSubmit = (values) => {
    let data = {
      description: values.description,
    };
    PagesIndex.DataService.post(PagesIndex.Api.CREATE_PRIVACY_POLICY, data)
      .then((res) => {
        if (res?.data) {
          PagesIndex.toast.success(res?.data?.message);
          getPrivacyPolicy();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const getPrivacyPolicy = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_PRIVACY_POLICY)
      .then((res) => {
        if (res?.data) {
          setPrivacyPolicy(res?.data?.data?.privacyPolicy);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("ckImage", file);
            Index.dataService
              .post("admin/ckImageEditor", body)
              .then((res) => {
                resolve({
                  default: `http://35.177.56.74:3027/uploads/${res.data.fileName}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      <Index.Box className="main-title">
        <Index.Typography className="head-title" component="h1">
          Privacy Policy
        </Index.Typography>
      </Index.Box>

      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={intialValues}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleFocus,
        }) => (
          <form onSubmit={handleSubmit}>
            <Index.Box className="barge-common-box cms-wrapper">
              <Index.Box className="">
                <Index.Box className="grid-row cms-page-row">
                  <Index.Box className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Description
                          </Index.Typography>
                          <Index.Box className="form-group">
                            <PagesIndex.CKEditor
                              config={{
                                extraPlugins: [uploadPlugin],
                              }}
                              editor={PagesIndex.ClassicEditor}
                              data={values.description}
                              name="description"
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("description", data);
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  mt={5}
                  ml={2}
                  className="save-btn-main border-btn-main"
                >
                  <Index.PrimaryButton
                    btnLabel="Update"
                    type="submit"
                    className="primary-btn add-game-btn cms-update-btn"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </form>
        )}
      </PagesIndex.Formik>
    </>
  );
};

export default PrivayPolicy;
