import React from "react";
import PagesIndex from "../../container/PagesIndex";

const PrivateLayout = () => {
  const location = PagesIndex.useLocation();
  const token = PagesIndex.useSelector((state) => state.admin.token);

  const isAdminLoggedIn = (token) => {
    if (!token) return false;
    PagesIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAdminLoggedIn(token) ? (
    <PagesIndex.Outlet />
  ) : (
    <PagesIndex.Navigate to="/" state={{ from: location }} replace={true} />
  );
};

export default PrivateLayout;
