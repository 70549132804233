import React, { useEffect, useState } from "react";
import "../games/addgames.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_URL_ENDPOINT } from "../../../../config/DataService";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddAdsBanner() {
  const [editBanner, setEditBanner] = useState([]);
  const navigate = useNavigate();
  const bannerId = useParams();

  let initialValues = {
    bannerId: "",
    bannerName: bannerId.id ? editBanner?.name : "",
    image: bannerId.id ? editBanner?.image : "",
    websiteLink: bannerId.id ? editBanner?.link : "",
  };

  const handleBannerAddEdit = (values) => {
    const urlEncoded = new FormData();
    urlEncoded.append("name", values?.bannerName);
    urlEncoded.append("image", values?.image);
    urlEncoded.append("link", values?.websiteLink);

    if (bannerId.id) {
      urlEncoded.append("id", bannerId.id);
    }


    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_BANNER, urlEncoded)
      .then((res) => {
        if (res?.data) {
          PagesIndex.toast.success(res.data.message);
          navigate("/admin/ads-banner");
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    PagesIndex.DataService.get(
      `${PagesIndex.Api.GET_BANNER_BY_ID}/${bannerId.id}`
    )
      .then((res) => {
        if (res?.data) {
          setEditBanner(res?.data?.data);
        }
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    if (editBanner?.image) {
      setImageUrl(`${IMAGE_URL_ENDPOINT}${editBanner?.image}`);
    }
  }, [editBanner?.image]);

  const handleImage = (file) => {
    let url = URL.createObjectURL(file);
    setImageUrl(url);
  };

  return (
    <Index.Box className="add-games-main">
      <Index.Box className="main-title">
        <Index.Typography className="head-title" component="h1">
          {bannerId.id ? "Edit Ads Banner" : "Add Ads Banner"}
        </Index.Typography>
        <Index.Box className="header-button-box">
          <Index.Link to="/admin/ads-banner" className="cus-link-btn">
            <Index.PrimaryButton
              btnLabel="Back"
              className="primary-btn add-game-btn"
            />
          </Index.Link>
        </Index.Box>
      </Index.Box>
      <Index.Box className="game-upload-wrapper">
        <Index.Box className="game-upload-header">
          {bannerId.id ? "Edit Ads Banner" : "Add Ads Banner"}
        </Index.Box>
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleBannerAddEdit}
          initialValues={initialValues}
          validationSchema={PagesIndex.validationSchemaBannerAddEdit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Index.Box className="game-upload-body">
                <Index.Box className="game-flex">
                  <Index.Box className="game-upload-logo-wrapper">
                    <Index.Typography className="game-title" component="p">
                      Banner Image
                    </Index.Typography>
                    <Index.Box className="game-upload-logo">
                      <img
                        src={imageUrl ? imageUrl : PagesIndex.Svg.add}
                        className={
                          imageUrl ? "game-upload-logo" : "upload-icon"
                        }
                        alt="add"
                      />
                      <Index.Button
                        className="upload-btn-main"
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        <VisuallyHiddenInput
                          accept="image/*"
                          name="image"
                          multiple
                          type="file"
                          onChange={(e) => {
                            try {
                              if (e.target?.files?.length != 0) {
                                props.setFieldValue("image", e.target.files[0]);
                                handleImage(e.target.files[0]);
                              }
                            } catch (error) {
                              e.target.value = null;
                            }
                          }}
                          error={
                            props.errors.image && props.touched.image
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.image && props.touched.image
                              ? props.errors.image
                              : false
                          }
                          onBlur={props.handleBlur}
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText error>
                      {props.errors.image && props.touched.image
                        ? props.errors.image
                        : false}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="game-upload-name">
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="game-title">
                        Brand Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="bannerName"
                          value={props.values.bannerName}
                          inputProps={{ maxLength: 30 }}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          onChange={props.handleChange}
                          error={
                            props.errors.bannerName && props.touched.bannerName
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.bannerName && props.touched.bannerName
                              ? props.errors.bannerName
                              : false
                          }
                          onBlur={props.handleBlur}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="game-title">
                        Website Link
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="websiteLink"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          value={props.values.websiteLink}
                          onChange={props.handleChange}
                          error={
                            props.errors.websiteLink &&
                            props.touched.websiteLink
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.websiteLink &&
                            props.touched.websiteLink
                              ? props.errors.websiteLink
                              : false
                          }
                          onBlur={props.handleBlur}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-game-btn-sec">
                  <Index.PrimaryButton
                    btnLabel={
                      !bannerId.id ? "Add Ads Banner" : "Update Ads Banner"
                    }
                    className="primary-btn add-game-btn"
                    type="submit"
                  />
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
}

export default AddAdsBanner;
