import React from 'react'
import Index from "../../Index";
import '../loader/loader.css'


const loader = () => {
    return (
        <>
            <Index.Box class="loader-wrapper cus-loader-wrapper">
                <Index.Box class="cssload-loader">
                    <Index.Box class="cssload-inner cssload-one"></Index.Box>
                    <Index.Box class="cssload-inner cssload-two"></Index.Box>
                    <Index.Box class="cssload-inner cssload-three"></Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default loader
