const Api = {
  ADMIN_LOGIN: "admin/login",
  FORGOT_PASSWORD: "admin/forgot-password",
  VARIFY_OTP: "admin/verify-otp",
  RESET_PASSWORD: "admin/reset-password",
  CHANGE_PASSWORD: "admin/changePassword",

  ADMIN_GET_ALL_USER_LIST: "admin/get-all-user",
  USER_ACTIVE_DEACTIVE: "admin/active-de-active-user",
  USER_DELETE: "admin/delete-user",

  GET_GENRS_LIST: "admin/genre-list",
  GET_SINGLE_GENRE: "admin/get-genre",
  ADD_EDIT_GENRE: "admin/add-edit-genre",
  GENRE_ACTIVE_DEACTIVE: "admin/genre-active-de-active",
  GENRE_DELETE: "admin/delete-genre",

  GET_SINGLE_GAME: "admin/get-game",
  GET_GAMES_LIST: "admin/game-list",
  ADD_EDIT_GAME: "admin/add-edit-game",
  GAME_ACTIVE_DEACTIVE: "admin/game-active-de-active",
  GAME_DELETE: "admin/delete-game",

  GET_BANNER_LIST: "admin/banner-list",
  GET_BANNER_STATUS: "admin/banner-active-de-active",
  ADD_EDIT_BANNER: "admin/add-edit-banner",
  DELETE_BANNER: "admin/delete-banner",
  GET_BANNER_BY_ID: "admin/get-banner",

  Edit_Profile_Password: "admin/profile-change-password",
  UPDATE_PROFILE: "admin/update-profile",
  GET_PROFILE: "admin/get-profile",
  CREATE_PRIVACY_POLICY: "admin/create-privacy-policy",
  CREATE_TERMS_AND_CONDITION: "admin/create-terms-and-condition",

  GET_DASHBOARD_DAY_BY_FILTER: "admin/admin-dashboard-statics",
  GET_PRIVACY_POLICY: "common/get-privacy-policy",
  GET_TERMS_AND_CONDITION: "common/get-terms-and-conditions",

  GET_LOCK_PERIOD: "common/lockperiod-list?list=true",
  ADD_EDIT_LOCK_PERIOD: "admin/add-edit-lockperiod",
  DELETE_LOCK_PERIOD: "admin/delete-lockperiod",

  GET_REWARD_NFT: "common/phase-detail-list?list=true",
  ADD_EDIT_REWARD_NFT: "admin/add-edit-phasedetail",
  DELETE_REWARD_NFT: "admin/delete-phasedetail",
};
export { Api };
