import React, { useState } from "react";
import "../games/addgames.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getRewardsList } from "../../../../redux/features/AddService";
import { IMAGE_URL_ENDPOINT } from "../../../../config/DataService";
import "../lockperiod/addlockperiod.css";
import { useAccount } from "wagmi";
import { Signer, ethers } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { DugoutObj } from "../../../../Connectivity/ContractObject";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddRewardsNFT() {
  const [imageUrl, setImageUrl] = useState("");
  const { state } = PagesIndex.useLocation();
  const [imageError, setImageError] = useState("");
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(false);
  const signer = useEthersSigner();
  const account = useAccount();

  let initialValues = {
    RewardsType: "",
    StartTokenID: "",
    EndTokenID: "",
    MetaData: "",
    description: "",
    gameLogo: "",
  };

  const addEditRewardCall = (urlEncoded) => {
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_REWARD_NFT, urlEncoded)
      .then((res) => {
        if (res?.status === 201) {
          PagesIndex.toast.success(res.data.message);
          navigate("/admin/rewards-nft");
        }
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const AddRewardNFTData = async (
    rewardType,
    _startTokenId,
    _endTokenId,
    metadata,
    urlEncoded
  ) => {
    try {
      const dugout = await DugoutObj(signer);
      const starttokenId = ethers.utils.parseEther(_startTokenId.toString());
      const endtokenId = ethers.utils.parseEther(_endTokenId.toString());
      let addphase = await dugout.addPhaseForNFT(
        rewardType,
        starttokenId,
        endtokenId,
        metadata
      );
      addEditRewardCall(urlEncoded);
    } catch (error) {
      setLoading(false);
      if (error?.error?.data?.message != undefined) {
        const err=error?.error?.data?.message?.split(":")
        PagesIndex.toast.error(err[err?.length - 1]);
      }
    }
  };

  const handleGamesEditForm = async (values) => {
    setLoading(true);
    if (account?.address) {
      const urlEncoded = new FormData();
      urlEncoded.append("rewardtype", values?.RewardsType);
      urlEncoded.append("starttokenid", values?.StartTokenID);
      urlEncoded.append("endtokenid", values?.EndTokenID);
      urlEncoded.append("metadata", values?.MetaData);
      urlEncoded.append("description", values?.description);
      urlEncoded.append("image", values?.gameLogo);

      if (state?.item?._id) {
        urlEncoded.append("id", state?.item?._id);
      }
      await AddRewardNFTData(
        values?.RewardsType,
        values?.StartTokenID,
        values?.EndTokenID,
        values?.MetaData,
        urlEncoded
      );
    } else {
      setLoading(false);
      connectionAlert();
    }
  };

  PagesIndex.useEffect(() => {
    if (state?.item?.image) {
      setImageUrl(`${IMAGE_URL_ENDPOINT}${state?.item?.image}`);
    }
  }, [state?.item?.image]);

  const handleImage = (file) => {
    if (file) {
      let url = URL.createObjectURL(file);
      setImageUrl(url);
    } else {
      setImageUrl("");
    }
  };

  const connectionAlert = () => {
    PagesIndex.toast.error("Please connect Metamask");
  };
  return (
    <>
      <Index.Box className="add-games-main add-lock-period-main">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Add Rewards NFT
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Link to="/admin/rewards-nft" className="cus-link-btn">
              <Index.PrimaryButton
                btnLabel="Back"
                className="primary-btn add-game-btn"
              />
            </Index.Link>
          </Index.Box>
        </Index.Box>
        {loading ? (
          <>
            <Index.loader />
          </>
        ) : (
          <Index.Box className="game-upload-wrapper">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleGamesEditForm}
              initialValues={initialValues}
              validationSchema={PagesIndex.validationSchemaRewardsAddEdit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                resetForm,
              }) => (
                <Index.Box
                  className="game-upload-body"
                  component="form"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="game-flex">
                    <Index.Box className="game-upload-name">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="dropdown-box game-genre">
                              <Index.Box className="game-upload-logo-wrapper rewards-image-box">
                                <Index.Typography
                                  className="game-title"
                                  component="p"
                                >
                                  Image
                                </Index.Typography>
                                <Index.Box className="game-upload-logo ">
                                  <img
                                    src={
                                      imageUrl ? imageUrl : PagesIndex.Svg.add
                                    }
                                    className={
                                      imageUrl
                                        ? "game-upload-logo"
                                        : "upload-icon"
                                    }
                                    alt="add"
                                  />
                                  <Index.Button
                                    className="upload-btn-main"
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    <VisuallyHiddenInput
                                      accept="image/*"
                                      name="gameLogo"
                                      type="file"
                                      onChange={(e) => {
                                        try {
                                          if (e.target?.files?.length !== 0) {
                                            const file = e.target.files[0];
                                            const allowedExtensions = [
                                              "jpg",
                                              "jpeg",
                                              "png",
                                            ];

                                            const extension = file.name
                                              .split(".")
                                              .pop()
                                              .toLowerCase();

                                            if (
                                              !allowedExtensions.includes(
                                                extension
                                              )
                                            ) {
                                              setImageError(
                                                "Please upload rewards image (only JPG, JPEG, and PNG files are allowed)"
                                              );
                                              handleImage(null);
                                            } else {
                                              setFieldValue("gameLogo", file);
                                              handleImage(file);
                                              setImageError("");
                                            }
                                          } else {
                                            handleImage(null);
                                            setFieldValue("gameLogo", "");
                                            setImageError(
                                              "Please upload rewards image (only JPG, JPEG, and PNG files are allowed)"
                                            );
                                          }
                                        } catch (error) {
                                          console.error(
                                            "Error handling image:",
                                            error
                                          );
                                          e.target.value = null;
                                          setFieldValue("gameLogo", "");
                                          setImageError("");
                                        }
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>

                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  <Index.FormHelperText
                                    error
                                    className="error-text"
                                  >
                                    {errors.gameLogo && touched.gameLogo
                                      ? errors.gameLogo
                                      : imageError
                                      ? imageError
                                      : null}
                                  </Index.FormHelperText>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Rewards Type
                              </Index.FormHelperText>

                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="RewardsType"
                                  name="RewardsType"
                                  onChange={(e) => {
                                    const inputValue = e?.target?.value?.replace(/[^A-Za-z ]/g, ''); 
                                    setFieldValue("RewardsType", inputValue);
                                  }}
                                  value={values.RewardsType}
                                  // onChange={handleChange} 
                                  onBlur={handleBlur}
                                  className="form-control"
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 32,
                                    },
                                  }}
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.RewardsType && touched.RewardsType
                                    ? errors.RewardsType
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box box-start-token">
                              <Index.Box className="input-box">
                                <Index.FormHelperText className="game-title">
                                  Start token
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="StartTokenID"
                                    name="StartTokenID"
                                    type="string"
                                    onChange={(e) => {
                                      const inputValue = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      );
                                      if (inputValue !== "0") {
                                        setFieldValue(
                                          "StartTokenID",
                                          inputValue.slice(0, 8)
                                        );
                                      }
                                    }}
                                    value={values.StartTokenID}
                                    onBlur={handleBlur}
                                    className="form-control"
                                  />
                                  <Index.FormHelperText
                                    error
                                    className="error-text"
                                  >
                                    {errors.StartTokenID && touched.StartTokenID
                                      ? errors.StartTokenID
                                      : null}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                End Token
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="EndTokenID"
                                  name="StartTokenID"
                                  type="string"
                                  onChange={(e) => {
                                    const inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue !== "0") {
                                      setFieldValue(
                                        "EndTokenID",
                                        inputValue.slice(0, 8)
                                      );
                                    }
                                  }}
                                  value={values.EndTokenID}
                                  onBlur={handleBlur}
                                  className="form-control"
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.EndTokenID && touched.EndTokenID
                                    ? errors.EndTokenID
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="game-title">
                                Meta data
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="MetaData"
                                  name="MetaData"
                                  type="string"
                                  onChange={handleChange}
                                  value={values.MetaData}
                                  onBlur={handleBlur}
                                  className="form-control "
                                  InputProps={{
                                    inputProps: {
                                      maxLength: 100,
                                    },
                                  }}
                                />
                                <Index.FormHelperText
                                  error
                                  className="error-text"
                                >
                                  {errors.MetaData && touched.MetaData
                                    ? errors.MetaData
                                    : null}
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="game-title"
                        >
                          Description
                        </Index.Typography>

                        <Index.Box
                          className="form-group"
                          style={{ marginTop: "12px" }}
                        >
                          <PagesIndex.CKEditor
                            editor={PagesIndex.ClassicEditor}
                            data={values.description}
                            name="description"
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              if (data?.length >= 180) {
                                event.stop();
                              }
                              setFieldValue("description", data);
                            }}
                          />
                          <Index.FormHelperText error className="error-text">
                            {errors.description && touched.description
                              ? errors.description
                              : null}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="add-game-btn-sec">
                        <Index.PrimaryButton
                          btnLabel="Add Rewards NFT"
                          className="primary-btn add-game-btn"
                          type="submit"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        )}
      </Index.Box>
    </>
  );
}

export default AddRewardsNFT;
