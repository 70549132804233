import React, { useState } from "react";
import "../admindashboard/admindashboard.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { DugoutObj } from "../../../../Connectivity/ContractObject";

function AdminDashboard() {
  const signer = useEthersSigner();
  const account = useAccount();
  const [changeTokenAddress, setChangeTokenAddress] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [spnTokenAddressError, setSpnTokenAddressError] = useState("");
  const [withdrawError, setWithdrawError] = useState({ token: "", amount: "" });

  const ChangeRewardToken = async (address) => {
    if (account?.address) {
      try {
        const dugout = await DugoutObj(signer);
        let changerewardToken = await dugout.changeRewardToken(address);
        PagesIndex.toast.success("SPN Token Address Updated Successfully");
        setChangeTokenAddress("");
      } catch (error) {
        const err = error?.error?.data?.message?.split(":");
        if (err != undefined) {
          PagesIndex.toast.error(err[err?.length - 1]);
        } 
        setChangeTokenAddress("");
      }
    } else {
      connectionAlert();
    }
  };

  const WithdrawFunds = async (address, withdrawalAmount) => {
    if (account?.address) {
      try {
        const dugout = await DugoutObj(signer);
        let amountTowei = ethers.utils.parseEther(withdrawalAmount).toString();

        let withdrawfunds = await dugout.withdrawFunds(address, amountTowei);
        PagesIndex.toast.success("Withdraw Funds SuccessFully");
        setReceiverAddress("");

        setAmount("");
      } catch (error) {
        const err = error?.error?.data?.message?.split(":");
        if (err != undefined) {
          PagesIndex.toast.error(err[err?.length - 1]);
        }
        setReceiverAddress("");
        setAmount("");
      }
    } else {
      connectionAlert();
    }
  };

  const connectionAlert = () => {
    PagesIndex.toast.error("Please connect Metamask");
  };

  return (
    <>
      <Index.Box className="main-admin-dashboard">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Admin Dashboard
          </Index.Typography>
        </Index.Box>
        <Index.Box className="admin-card-wrapper">
          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 4 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-rate-box">
                    <Index.Typography
                      className="admin-card-title"
                      component="h1"
                    >
                      Withdraw Funds
                    </Index.Typography>
                    <Index.Typography
                      className="admin-card-subtitle"
                      component="h1"
                    >
                      Receiver Address :
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Receiver Address"
                          value={receiverAddress}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^[a-zA-Z0-9]*$/.test(inputValue) &&
                              inputValue.length <= 64
                            ) {
                              setReceiverAddress(inputValue);
                              setWithdrawError({ ...withdrawError, token: "" });
                            }
                            if (!inputValue) {
                              setWithdrawError({
                                ...withdrawError,
                                token: "Please enter receiver address",
                              });
                            }
                          }}
                        />
                        <Index.FormHelperText error className="error-text">
                          {withdrawError.token ? withdrawError.token : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Typography
                      className="admin-card-subtitle"
                      component="h1"
                    >
                      Amount of Tokens :
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Amount of Tokens"
                          value={amount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^[0-9]*$/.test(inputValue) &&
                              inputValue.length <= 12
                            ) {
                              if (inputValue !== "0") {
                                setAmount(inputValue);
                                setWithdrawError({
                                  ...withdrawError,
                                  amount: "",
                                });
                              } else {
                                setAmount("");
                              }
                            }
                            if (!inputValue) {
                              setWithdrawError({
                                ...withdrawError,
                                amount: "Please enter amount of tokens",
                              });
                            }
                          }}
                        />
                        <Index.FormHelperText error className="error-text">
                          {withdrawError.amount ? withdrawError.amount : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.PrimaryButton
                      btnLabel="Withdraw"
                      type="submit"
                      className="primary-btn add-game-btn"
                      onClick={() => {
                        !receiverAddress && !amount
                          ? setWithdrawError({
                              token: receiverAddress
                                ? ""
                                : "Please enter receiver address",
                              amount: amount
                                ? ""
                                : "Please enter amount of tokens",
                            })
                          : WithdrawFunds(receiverAddress, amount);
                      }}
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-rate-box">
                    <Index.Typography
                      className="admin-card-title"
                      component="h1"
                    >
                      Change SPN Token Address
                    </Index.Typography>
                    <Index.Typography
                      className="admin-card-subtitle"
                      component="h1"
                    >
                      Enter Adddress of Change SPN :
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Enter Adddress of ChangeSPN"
                          value={changeTokenAddress}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^[a-zA-Z0-9]*$/.test(inputValue) &&
                              inputValue.length <= 64
                            ) {
                              setChangeTokenAddress(inputValue);
                              setSpnTokenAddressError("");
                            }
                            if (!inputValue) {
                              setSpnTokenAddressError(
                                "Please enter spn token address"
                              );
                            }
                          }}
                        />
                        <Index.FormHelperText error className="error-text">
                          {spnTokenAddressError ? spnTokenAddressError : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.PrimaryButton
                      btnLabel="Change Address"
                      type="submit"
                      className="primary-btn add-game-btn"
                      onClick={() => {
                        changeTokenAddress
                          ? ChangeRewardToken(changeTokenAddress)
                          : setSpnTokenAddressError(
                              "Please enter spn token address "
                            );
                      }}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AdminDashboard;
