import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../../container/PagesIndex";

const Dashboard = () => {

  const [age, setAge] = React.useState("weekly");

  const [dashboadDataTotalUsers, setDashboardDataTotalUsers] = useState(0);
  const [dashboadDataActiveUsers, setDashboardDataActiveUsers] = useState(0);

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
    const value = event.target.value;

    getDashboardData(value);
  };

  const navigate = PagesIndex.useNavigate();

  const getDashboardData = (value) => {
    PagesIndex.DataService.get(
      `${PagesIndex.Api.GET_DASHBOARD_DAY_BY_FILTER}?filterBy=${value}`
    )
      .then((res) => {
        if (value == "monthly") {
          setDashboardDataTotalUsers(res?.data?.data?.monthlyTotalUser);
          setDashboardDataActiveUsers(res?.data?.data?.monthlyActiveUser);
        } else if (value == "yearly") {
          setDashboardDataTotalUsers(res?.data?.data?.yearlyTotalUser);
          setDashboardDataActiveUsers(res?.data?.data?.yearlyActiveUser);
        } else if (value == "weekly") {
          setDashboardDataTotalUsers(res?.data?.data?.weeklyTotalUser);
          setDashboardDataActiveUsers(res?.data?.data?.weeklyActiveUser);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getDashboardData(age);
  }, []);
  return (
    <Index.Box className="main-dashboard">
      <Index.Box className="main-title">
        <Index.Typography className="head-title" component="h1">
          Dashboard
        </Index.Typography>
        <Index.Box className="header-drop-box">
          <Index.Box className="dropdown-box">
            <Index.FormControl className="form-control drop-form-control">
              <Index.Select
                className="dropdown-select drop-select"
                value={age}
                onChange={handleChangedropdown}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <Index.MenuItem value="weekly" className="drop-menuitem">
                  Weekly
                </Index.MenuItem>
                <Index.MenuItem value="monthly" className="drop-menuitem">
                  Monthly
                </Index.MenuItem>
                <Index.MenuItem value="yearly" className="drop-menuitem">
                  Yearly
                </Index.MenuItem>
              </Index.Select>
            </Index.FormControl>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="dash-card-wrapper">
        <Index.Box className="admin-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="dash-box">
                  <Index.Box className="card-icon-sec">
                    <img
                      src={PagesIndex.Svg.white_user}
                      className="card-img"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="card-content-sec">
                    <Index.Typography
                      className="card-title"
                      component="p"
                      onClick={() => {
                        navigate("/admin/user-managment", {
                          state: "total_users",
                        });
                      }}
                    >
                      Total users
                    </Index.Typography>
                    <Index.Typography className="card-value" component="p">
                      {dashboadDataTotalUsers}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="dash-box">
                  <Index.Box className="card-icon-sec">
                    <img
                      src={PagesIndex.Svg.whiteCheck}
                      className="card-img"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="card-content-sec">
                    <Index.Typography className="card-title" component="p">
                      Total Active users
                    </Index.Typography>
                    <Index.Typography
                      className="card-value active-user"
                      component="p"
                    >
                      {dashboadDataActiveUsers}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="dash-box">
                  <Index.Box className="card-icon-sec">
                    <img
                      src={PagesIndex.Svg.white_games}
                      className="card-img"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="card-content-sec">
                    <Index.Typography className="card-title" component="p">
                      Total Games Played
                    </Index.Typography>
                    <Index.Typography className="card-value" component="p">
                      200K
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 8",
                }}
                className="grid-column"
              >
                <Index.Box className="dash-box coin-purchase-box">
                  <Index.Box className="card-icon-sec">
                    <img
                      src={PagesIndex.Svg.whiteInsertCoin}
                      className="card-img"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="card-content-sec">
                    <Index.Typography className="card-title" component="p">
                      Total Coins Purchased
                    </Index.Typography>
                    <Index.Typography className="card-dis-text" component="p">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less. It is a long established fact that a
                      reader will be.
                    </Index.Typography>
                    <Index.Box className="coin-text-wrapper">
                      <img
                        src={PagesIndex.Png.coinIc}
                        className="coin-img"
                        alt="user"
                      />
                      <Index.Typography className="card-value" component="p">
                        300 Billions
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="dash-box total-revenue-box">
                  <Index.Box className="card-icon-sec">
                    <img
                      src={PagesIndex.Svg.whiteGrowth}
                      className="card-img"
                      alt="user"
                    />
                  </Index.Box>
                  <Index.Box className="card-content-sec">
                    <Index.Typography className="card-title" component="p">
                      Total Revenue
                    </Index.Typography>
                    <Index.Typography className="card-dis-text" component="p">
                      It is a long established fact that a reader will be
                      distrac by the readable content of a page when looking at.
                    </Index.Typography>
                    <Index.Box className="coin-text-wrapper">
                 
                      <Index.Typography className="card-value" component="p">
                        10 Billions
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Dashboard;
