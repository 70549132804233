import React, { useState } from "react";
import "../games/game.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getGamesList } from "../../../../redux/features/AddService";
import {
  clearAllData,
  clearSearchResults,
  selectSearchResults,
  setAllData,
  setSearchResults,
} from "../../../../redux/features/adminslice/AdminSlice";

function Games() {
  let [currentRows, setCurrentRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  let currentData = PagesIndex.useSelector(selectSearchResults);

  PagesIndex.useEffect(() => {
    setTableData(currentData);
  }, [currentData]);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const itemsPerPage = 10;

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  PagesIndex.useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = tableData?.slice(startIndex, endIndex);

    setCurrentRows(displayedItems);
  }, [tableData, page]);

  const fetchListData = () => {
    dispatch(getGamesList(true)).then((res) => {
      if (res?.payload?.status == 200) {
        setTableData(res?.payload?.data);
        dispatch(setAllData(res?.payload?.data));
        dispatch(setSearchResults(res?.payload?.data));
      }
    });
  };
  PagesIndex.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    dispatch(clearSearchResults());
    dispatch(clearAllData());
    fetchListData();
  }, []);

  const handleSwitchToggle = (gameId) => {
    const data = {
      id: gameId,
    };


    PagesIndex.DataService.post(PagesIndex.Api.GAME_ACTIVE_DEACTIVE, data)
      .then((res) => {

        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handleAddEdit = (mode, item) => {
    const editData = {
      item: mode === "Edit" ? item : null,
      mode: mode,
    };
    navigate("/admin/games/add", { state: editData });
  };

 
  const handleDeleteUser = (data) => {
    const payload = {
      gameIds: data,
    };
  
    PagesIndex.DataService.post(PagesIndex.Api.GAME_DELETE, payload)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
          setSelected([]);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handlecheckDelete = () => {
    handleDeleteUser(selected);
  };

  const handleSelect = (itemId) => {
    if (selected?.includes(itemId)) {
      setSelected(selected?.filter((id) => id !== itemId));
    } else {
      setSelected([...selected, itemId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
    } else {
      setSelected(currentRows?.map((item) => item._id));
    }
    setSelectAll(!selectAll);
  };

  PagesIndex.useEffect(() => {
    if (selected?.length === currentRows?.length && currentRows?.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selected, currentRows]);

  const [DeleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (id) => {
    const newId = [id];
    setDeleteId(newId);
    setOpenDelete(true);
  };
  const handleDeleteSingleItem = () => {
    handleDeleteUser(DeleteId);
    handleCloseDelete();
  };
  const handleCloseDelete = () => setOpenDelete(false);
  return (
    <>
      <Index.Box className="main-dashboard">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Games List
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Box
              className={`d-flex align-items-center res-set-search ${
                selected?.length > 0 ? "" : "btn_optional"
              }`}
            >
              {selected?.length > 1 ||
              selected?.length === currentRows?.length ? (
                <Index.PrimaryButton
                  btnLabel=" Delete All Selected"
                  className="primary-btn add-game-btn primary_btn_icon_remove"
                  onClick={() => handlecheckDelete()}
                />
              ) : selected?.length ? (
                <Index.PrimaryButton
                  btnLabel=" Delete Selected"
                  className="primary-btn add-game-btn primary_btn_icon_remove"
                  onClick={() => handlecheckDelete()}
                />
              ) : null}
            </Index.Box>
            <Index.PrimaryButton
              btnLabel="Add Games"
              imgSrc={PagesIndex.Svg.add}
              className="primary-btn add-game-btn"
              onClick={() => {
                handleAddEdit("Add");
              }}
            />

            <Index.Box className="common-button blue-button res-blue-button"></Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box className="sahay-table cus-table-wrapper">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main barge-table"
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell align="left">
                        <Index.Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          className="cus-table-checkbox"
                          variant="th"
                          align="center"
                        />
                      </Index.TableCell>
                      <Index.TableCell align="left">No.</Index.TableCell>
                      {/* <Index.TableCell align="left">User ID</Index.TableCell> */}
                      <Index.TableCell align="left">Games Name</Index.TableCell>
                      <Index.TableCell align="left">
                        Created Date
                      </Index.TableCell>
                      <Index.TableCell align="left">Status</Index.TableCell>
                      <Index.TableCell align="left">Action</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  {loading ? (
                    <Index.TableBody className="table-body">
                      <Index.TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <Index.TableCell
                          variant="td"
                          scope="row"
                          className="table-td"
                          align="center"
                          colSpan={6}
                        >
                          <Index.loader />
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  ) : (
                    <Index.TableBody>
                      {currentRows?.length > 0 ? (
                        currentRows?.map((item, index, row) => (
                          <Index.TableRow key={item.id}>
                            <Index.TableCell>

                              <Index.Checkbox
                                variant="td"
                                scope="row"
                                className="cus-table-checkbox"
                                align="center"
                                checked={selected?.includes(item?._id)}
                                onChange={() => handleSelect(item?._id)}
                              />
                            </Index.TableCell>
                            <Index.TableCell align="left">
                            {(page - 1) * itemsPerPage + index + 1}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.name}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {PagesIndex.dayjs(item?.createdAt).format(
                                "DD-MM-YYYY"
                              )}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.isActive === true ? (
                                <Index.Button
                                  variant="contained"
                                  className="table-action-btn active-status"
                                >
                                  Active
                                </Index.Button>
                              ) : (
                                <Index.Button
                                  variant="contained"
                                  className="table-action-btn inactive-status"
                                >
                                  Inactive
                                </Index.Button>
                              )}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="gray-btn-main">
                                {/* <Index.Link to={"/admin/edit-genres"}> */}
                                <Index.Box
                                  className="gray-btn"
                                  onClick={() => {
                                    handleAddEdit("Edit", item);
                                  }}
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteDraw}
                                    className="action-btn-img"
                                    alt="user"
                                  />
                                </Index.Box>
                                <Index.Button
                                  className="gray-btn"
                                  onClick={() => handleOpenDelete(item?._id)}
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteDelete}
                                    className="action-btn-img"
                                    alt="user"
                                  />
                                </Index.Button>
                                <Index.Switch
                                  defaultChecked={item?.isActive}
                                  onClick={() => handleSwitchToggle(item._id)}
                                ></Index.Switch>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.NoDataFound colSpan={6} />
                      )}
                    </Index.TableBody>
                  )}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-design flex-start">
            <Index.Stack spacing={2}>
              <Index.Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.whiteDelete} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={() => handleDeleteSingleItem()}
            >
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default Games;
