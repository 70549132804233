import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";

function NewResetPassword() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const adminId = state?.adminId;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const handleClickConfirmShowPassword = () => setConfirmShowPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const handleResetFormSubmit = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("newPassword", values?.newPassword);
    urlEncoded.append("confirmPassword", values?.confirmPassword);
    urlEncoded.append("adminId", adminId);   
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.RESET_PASSWORD,
        urlEncoded
      );
      PagesIndex.toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Index.Box className="auth-wrapper">
        <Index.Box className="auth-sec-left">
          <img src={PagesIndex.Png.authImg} className="auth-img" alt="signup" />
        </Index.Box>
        <Index.Box className="auth-sec-right">
          <PagesIndex.Formik
            onSubmit={handleResetFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaResetPassword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
            }) => (
              <Index.Box
                component="form"
                onSubmit={handleSubmit}
                className="form-main-wrapper"
              >
                <img
                  src={PagesIndex.Png.login_logo}
                  className="login-logo"
                  alt="signup"
                />
                <Index.Typography className="auth-title" component="h5">
                  RESET PASSWORD
                </Index.Typography>
                {/* <Index.Box className="input-box">
                  <Index.Box className="form-group">
                    <Index.TextField                  
                      fullWidth
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      name="newPassword"
                      onBlur={handleBlur}
                      value={values.newPassword}
                      onChange={handleChange}
                      helperText={
                        errors.newPassword && touched.newPassword
                          ? errors.newPassword
                          : null
                      }
                      error={
                        errors.newPassword && touched.newPassword ? true : false
                      }
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className=
                "input-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      helperText={
                        errors.confirmPassword && touched.confirmPassword
                          ? errors.confirmPassword
                          : null
                      }
                      error={
                        errors.confirmPassword && touched.confirmPassword
                          ? true
                          : false
                      }
                    />
                  </Index.Box>
                </Index.Box> */}


                     <Index.Box className="input-box mb-0">
                
                    <Index.Box className="form-group pass_group_main reset-text-box">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control"

                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="New Password"
                        name="newPassword"
                     
                        value={values.newPassword}
                     
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment
                            position="end"
                            className="pass_position"
                          >
                            <Index.IconButton
                              className="icon_end_icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.newPassword && touched.newPassword
                        ? errors.newPassword
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>

                <Index.Box className="input-box mb-0 reset-textfeld">
                
                <Index.Box className="form-group pass_group_main reset-text-box">
                  <Index.OutlinedInput
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    onChange={handleChange}

                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment
                        position="end"
                        className="pass_position"
                      >
                        <Index.IconButton
                          className="icon_end_icon"
                          aria-label="toggle password visibility"
                          onClick={handleClickConfirmShowPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                  />
                </Index.Box>
                <Index.FormHelperText error className="error-text">
                  {errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : null}
                </Index.FormHelperText>
              </Index.Box>
            

                
                <Index.PrimaryButton
                  btnLabel="Submit"
                  type="submit"
                  className="primary-btn login reset-password"
                />
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NewResetPassword;
