import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import { forgotpasswordAdmin } from "../../../../redux/features/AddService";

function NewForgotPassword() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  let initialValues = {
    email: "",
  };

  const handleForgotFormSubmit = (values) => {
    dispatch(forgotpasswordAdmin(values, navigate)).then((res) => {
      if (res.payload !== undefined) {
        const adminId = res?.payload?.data?.id;
        setTimeout(() => {
          navigate("/admin/verify-otp", { state: { adminId } });
        }, 1000);
      }
    });
  };

  return (
    <>
      <Index.Box className="auth-wrapper">
        <Index.Box className="auth-sec-left">
          <img src={PagesIndex.Png.authImg} className="auth-img" alt="signup" />
        </Index.Box>
        <Index.Box className="auth-sec-right">
          <PagesIndex.Formik
            onSubmit={handleForgotFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaForgotpassword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleFocus,
            }) => (
              <Index.Box
                component="form"
                onSubmit={handleSubmit}
                className="form-main-wrapper"
              >
                <img
                  src={PagesIndex.Png.login_logo}
                  className="login-logo"
                  alt="signup"
                />
                <Index.Typography className="auth-title" component="h5">
                  FORGOT PASSWORD
                </Index.Typography>
                <Index.Box className="input-box mb-0">
                  <Index.FormHelperText className="form-lable">
                    Email Address <span>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Enter Your Email Address"
                      name="email"
                      data-testid="email-input"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Link className="forgot-link" to={"/"}>
                  Go to login page?
                </Index.Link>
                <Index.PrimaryButton
                  btnLabel="Send"
                  type="submit"
                  className="primary-btn login"
                />
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NewForgotPassword;
