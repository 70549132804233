import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useSelector } from "react-redux";

function ChangePassword() {
  const dataId = useSelector((state) => state.admin.adminData.id);

  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleFormSubmit = async (values) => {
    const data = {
      ...values,
      id: dataId,
    };
  };

  return (
    <div>
      <Index.Box className="p-15 background-ed profile-content flex-center ">
        <Index.Box className="h-100">
          <Index.Box className="card-center">
            <Index.Box className="card-main change-password-main">
              <Index.Box className="title-main change-password-text">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Change Password
                </Index.Typography>
              </Index.Box>
              <Index.Box className="input-design-div admin-design-div input-design-div-set-back change-password-icon ">
                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.validationSchemaChangepassword}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label"
                      >
                        Old Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="Old Password"
                        className="admin-input-design input-placeholder password-set"
                        name="oldPassword"
                        type={showOldPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props ",
                        }}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        onChange={handleChange}
                        helperText={touched.oldPassword && errors.oldPassword}
                        error={Boolean(
                          errors.oldPassword && touched.oldPassword
                        )}
                        InputProps={{
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label label-set "
                      >
                        New Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="New Password"
                        className="admin-input-design input-placeholder password-set"
                        name="newPassword"
                        type={showNewPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props",
                        }}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        onChange={handleChange}
                        error={Boolean(
                          errors.newPassword && touched.newPassword
                        )}
                        InputProps={{
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      {errors.newPassword && touched.newPassword && (
                        <Index.FormHelperText
                          sx={{
                            marginTop: "0px !important",
                            marginBottom: "10px !important",
                            wordBreak: "break-word",
                            width: "300px",
                          }}
                          error
                          id="outlined-adornment-password"
                        >
                          {errors.newPassword}
                        </Index.FormHelperText>
                      )}
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label label-set "
                      >
                        Confirm Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="Confirm Password"
                        className="admin-input-design input-placeholder password-set-box"
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props",
                        }}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        error={Boolean(
                          errors.confirmPassword && touched.confirmPassword
                        )}
                        sx={{ mb: 3 }}
                        InputProps={{
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      <Index.Box
                        className="common-button blue-button change-password-btn"
                        sx={{ mt: 4 }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disableRipple
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default ChangePassword;
