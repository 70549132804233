import React, { useState } from "react";
import "./Login.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { loginAdmin } from "../../../../redux/features/AddService";
import "../../auth/auth.responsive.css";
function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  let initialValues = {
    email: "",
    password: "",
  };

  const handleLoginFormSubmit = (values) => {
    dispatch(loginAdmin(values, navigate)).then((res) => {
      if (res.payload !== undefined) {
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 1000);
      }
    });
  };

  return (
    <>
      <Index.Box className="auth-wrapper">
        <Index.Box className="auth-sec-left">
          <img src={PagesIndex.Png.authImg} className="auth-img" alt="signup" />
        </Index.Box>
        <Index.Box className="auth-sec-right">
          <Index.Box className="form-main-wrapper">
            <img
              src={PagesIndex.Png.login_logo}
              className="login-logo"
              alt="signup"
            />
            <Index.Typography className="auth-title" component="h5">
              Welcome Back!
            </Index.Typography>

            <PagesIndex.Formik
              onSubmit={handleLoginFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.validationSchemaLogin}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
              }) => (
                <Index.Box
                  className="login_from_wrap"
                  onSubmit={handleSubmit}
                  component="form"
                >
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Email Address <span>*</span>
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        name="email"
                        data-testid="email-input"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.email}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box mb-0">
                    <Index.FormHelperText className="form-lable">
                      Password <span>*</span>
                    </Index.FormHelperText>
                    <Index.Box className="form-group pass_group_main">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control custom_form_control"
                        placeholder="Enter Your Password"
                        name="password"
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        error={Boolean(errors.password && touched.password)}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment
                            position="end"
                            className="pass_position"
                          >
                            <Index.IconButton
                              className="icon_end_icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.password && touched.password
                        ? errors.password
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Link
                    className="forgot-link login-forgot-text"
                    to={"/admin/forgot-password"}
                  >
                    Forgot Password?
                  </Index.Link>
                  <Index.PrimaryButton
                    btnLabel="Log in"
                    className="primary-btn login"
                    type="submit"
                  />
                </Index.Box>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Login;
