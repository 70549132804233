import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Sidebar from "../../../../components/admin/defaultLayout/Sidebar";
import "./dashboard.css";
import "./dashboard.responsive.css";
import PagesIndex from "../../../PagesIndex";
import { toast } from "react-toastify";
import {
  logoutAdmin,
  setSearchResults,
} from "../../../../redux/features/adminslice/AdminSlice";
import { useSelector } from "react-redux";
import { IMAGE_URL_ENDPOINT } from "../../../../config/DataService";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { Signer, ethers } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { useDisconnect } from "wagmi";

const DashboardLayout = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { disconnect } = useDisconnect();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const allResults = PagesIndex.useSelector((state) => state.admin.allData);
  const [searchQuery, setSearchQuery] = useState("");
  const open2 = Boolean(anchorEl);
  const { open, close } = useWeb3Modal();
  const signer = useEthersSigner();
  const { account, address, isConnected } = useAccount();
  const adminData = useSelector((state) => state.admin.adminData);

  const handlesubmitforConnect = async () => {
    await open();
  };

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList["add"]("menu-set-main");
  };

  const handleClose2 = () => {
    setAnchorEl(null);
    document.body.classList["remove"]("menu-set-main");
  };

  const handleLogout = () => {
    dispatch(logoutAdmin());
    navigate("/");
    toast.success("Logged out");
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query != "") {
      const filteredResults = allResults?.filter((result) =>
        result?.name?.toLowerCase().includes(query?.toLowerCase()) ||
        result?.rewardtype?.toLowerCase().includes(query?.toLowerCase())
      );
      
      dispatch(setSearchResults(filteredResults));
  
    } else {
      dispatch(setSearchResults(allResults));
    }
  };

  const [openSiderbar, setOpenSiderbar] = useState(false);
  useEffect(() => {
    document.body.classList[openSiderbar ? "add" : "remove"]("body-no-class");
  }, [openSiderbar]);


  return (
    <div className="main-dashboard">
      <Index.Box
        className={`${openSiderbar ? "overlay" : ""}`}
        onClick={() => setOpenSiderbar((e) => !e)}
      ></Index.Box>
      <Sidebar setOpen={setOpenSiderbar} open={openSiderbar} />
      <Index.Box
        className={`right-dashboard-content ${openSiderbar ? "active" : ""} `}
      >
        <header>
          <Index.Box
            className={`main-header ${!openSiderbar ? "" : "pl-none"}`}
          >
            <Index.Box className="main-header-left-side-box">
              <Index.Box
                className="collapse-btn"
                onClick={() => setOpenSiderbar((e) => !e)}
              >
                <img src={PagesIndex.Svg.collapse} />
              </Index.Box>
              <Index.Box
                sx={{
                  width: 300,
                  maxWidth: "100%",
                }}
              >
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  size="small"
                  placeholder="search..."
                  className="header-textfield"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </Index.Box>
            </Index.Box>
          

            <Index.Box className="admin-header-right">
              <Index.Box className="meta-wallet">
                {!isConnected ? (
                  <Index.Button
                    className="metamask-btn"
                    onClick={handlesubmitforConnect}
                  >
                    <img
                      src={PagesIndex.Svg.wallet}
                      alt="MetaMask"
                      className="metamask-img"
                    />
                    Connect <br /> Wallet
                    <Index.Typography
                      component="p"
                      className="metamsk-text"
                    ></Index.Typography>
                  </Index.Button>
                ) : (
                  <Index.Box className="metamask-btn meta-address">
                    {`${address.slice(0, 6)}...${address.slice(-4)}`}
                  </Index.Box>
                )}
              </Index.Box>

              <Index.Box className="admin-header-drop-main">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={openSiderbar ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSiderbar ? "true" : undefined}
                  onClick={handleClick2}
                >
                  <Index.Box className="head-right">
                    <Index.Box className="header-user-detail">
                      <Index.Typography variant="p" className="head-user-title">
                        {adminData?.name}
                      </Index.Typography>
                      <Index.Typography variant="p" className="head-user-mail">
                        {adminData?.email}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="header-right-background">
                      <img
                        src={
                          adminData?.image
                            ? `${IMAGE_URL_ENDPOINT}${adminData?.image}`
                            : PagesIndex.Png.profile1
                        }
                        className="headprofile"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className="drop-header-menu admin-header-profile-ul"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <PagesIndex.Link to="/admin/edit-profile">
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img
                      src={PagesIndex.Svg.profilegrey}
                      className="drop-header"
                    />{" "}
                    Profile
                  </Index.MenuItem>
                </PagesIndex.Link>
              
                <Index.MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                  className="drop-header-menuitem logout-profile"
                >
                  {" "}
                  <img src={PagesIndex.Svg.edit} className="drop-header" />{" "}
                  Logout
                </Index.MenuItem>
                <Index.MenuItem
                  className="drop-header-menuitem "
                  onClick={() => {
                    disconnect();
                    handleClose2();
                  }}
                >
                  {" "}
                  <img src={PagesIndex.Svg.edit} className="drop-header" />{" "}
                  Wallet Disconnect
                </Index.MenuItem>
              </Index.Menu>
            </Index.Box>
          </Index.Box>
        </header>
        <Index.Box
          className={`admin-panel-content ${!openSiderbar ? "" : "pl-none"}`}
        >
          <PagesIndex.Outlet />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default DashboardLayout;
