import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PropTypes from "prop-types";
import PagesIndex from "../../../PagesIndex";
import "./editprofile.css";
import "../games/addgames.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

import {
  changePasswordProfile,
  editProfile,
  editProfileWithOtp,
  getProfile,
} from "../../../../redux/features/AddService";
import { useSelector } from "react-redux";
import { IMAGE_URL_ENDPOINT } from "../../../../config/DataService";
import * as yup from "yup";

const validFileExtensions = {
  image: ["jpg", "png", "jpeg", "svg", "webp"],
};

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType]?.indexOf(fileName?.split(".").pop()) > -1
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function EditProfile() {
  const [value, setValue] = React.useState(0);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [touched, setTouched] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = PagesIndex.useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const adminId = useSelector((state) => state.admin.adminData);

  const [loading, setLoading] = useState(false);

  const validationSchemaEditProfile = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter valid email"
      )
      .required("Please enter email"),
    name: yup.string().required("Please enter name"),
    image: yup
      .mixed()
      .required("Please upload profile image")
      .test(
        "is-valid-type",
        "Please upload only .jpg, .png, .jpeg, or .svg files",
        (value) =>
          typeof value !== "object"
            ? isValidFileType(value, "image")
            : isValidFileType(value && value?.name?.toLowerCase(), "image")
      ),
    otp: showVerifyOtp
      ? yup.string().required("OTP is required when email is provided")
      : yup.string().notRequired(),
  });

  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  let profileInitialvalues = {
    image: adminId ? adminId?.image : "",
    name: adminId ? adminId?.name : "",
    email: adminId ? adminId?.email : "",
    otp: "",
  };

  const handleChangeProfile = (values, { resetForm }) => {
    const urlEncoded = new FormData();
    urlEncoded.append("id", adminId?._id);
    urlEncoded.append("name", values?.name);
    urlEncoded.append("image", values?.image);
    urlEncoded.append("email", values?.email);
    if (touched) {
      setShowVerifyOtp(true);
    }

    if (values?.otp && touched) {
      urlEncoded.append("otp", values?.otp);
      urlEncoded.append("isUpdateCall", true);
      dispatch(editProfileWithOtp(urlEncoded)).then((res) => {
        dispatch(getProfile()).then((res) => {
          setLoading(true);

          setTimeout(() => {
            setLoading(false);
          }, 0);
        });

        setShowVerifyOtp(false);
        setTouched(false);
        resetForm();
      });
    } else {
      dispatch(editProfile(urlEncoded)).then((res) => {
        dispatch(getProfile());
      });
    }
  };

  const handleChangePassword = (values, { resetForm }) => {
    const newObj = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    };

    dispatch(changePasswordProfile(newObj));
    resetForm();
  };

  useEffect(() => {
    if (adminId?.image) {
      setImageUrl(`${IMAGE_URL_ENDPOINT}${adminId?.image}`);
    }
  }, [adminId?.image]);

  const handleImage = (file) => {
    let url = URL.createObjectURL(file);
    setImageUrl(url);
  };

  return (
    <>
      {loading ? (
        <Index.loader />
      ) : (
        <Index.Box className="main-dashboard dashboard-content edit-profile-containt">
          <Index.Box className="main-title">
            <Index.Typography className="head-title" component="h1">
              Account Settings
            </Index.Typography>
          </Index.Box>
          <Index.Box className="tabs-main-box">
            <Index.Box sx={{ width: "100%" }}>
              <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Index.Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="admin-tabs-main"
                >
                  <Index.Tab
                    label="Edit Profile"
                    {...a11yProps(0)}
                    className="admin-tab"
                  />
                  <Index.Tab
                    label="Change Password"
                    {...a11yProps(1)}
                    className="admin-tab"
                  />
                </Index.Tabs>
              </Index.Box>
              <TabPanel value={value} index={0} className="admin-tabpanel">
                <Index.Box className="tabpanel-main">
                  <PagesIndex.Formik
                    initialValues={profileInitialvalues}
                    onSubmit={handleChangeProfile}
                    validationSchema={validationSchemaEditProfile}
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <Index.Box className="edit-profile-tab game-upload-body p-0">
                          <Index.Box className="game-upload-logo">
                            <img
                              src={imageUrl ? imageUrl : PagesIndex.Svg.add}
                              className={
                                imageUrl ? "game-upload-logo" : "upload-icon"
                              }
                              alt="add"
                            />
                            <Index.Button
                              className="upload-btn-main"
                              component="label"
                              variant="contained"
                              startIcon={<CloudUploadIcon />}
                            >
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                name="image"
                                multiple
                                onChange={(e) => {
                                  try {
                                    if (e.target?.files?.length != 0) {
                                      props.setFieldValue(
                                        "image",
                                        e.target.files[0]
                                      );
                                      handleImage(e.target.files[0]);
                                    }
                                  } catch (error) {
                                    e.target.value = null;
                                  }
                                }}
                                error={
                                  props.errors.image && props.touched.image
                                    ? true
                                    : false
                                }
                                helperText={
                                  props.errors.image && props.touched.image
                                    ? props.errors.image
                                    : false
                                }
                                onBlur={props.handleBlur}
                              />
                            </Index.Button>
                          </Index.Box>

                          <Index.Box className="admin-dashboad-row edit-profile-form">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                className="display-row"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="game-title">
                                      Name
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        name="name"
                                        inputProps={{ maxLength: 30 }}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.value.trim() === ""
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        helperText={
                                          props.touched.name &&
                                          props.errors.name
                                        }
                                        error={Boolean(
                                          props.errors.name &&
                                            props.touched.name
                                        )}
                                        onBlur={props.handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="game-title">
                                      Email
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        name="email"
                                        value={props.values.email}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === " " &&
                                            e.target.value.trim() === ""
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={props.handleChange}
                                        onClick={() => {
                                          setTouched(true);
                                        }}
                                        helperText={
                                          props.touched.email &&
                                          props.errors.email
                                        }
                                        error={Boolean(
                                          props.errors.email &&
                                            props.touched.email
                                        )}
                                        onBlur={props.handleBlur}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="header-button-box">
                                    <Index.Box className="add-game-btn-sec save-btn">
                                      <Index.PrimaryButton
                                        btnLabel="Save"
                                        type="submit"
                                        className="primary-btn add-game-btn"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                {showVerifyOtp ? (
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-box  otp-verification">
                                      <Index.FormHelperText className="game-title">
                                        Otp verify
                                      </Index.FormHelperText>
                                      <Index.Box className="form-group">
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control"
                                          value={props.values.otp}
                                          name="otp"
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === " " &&
                                              e.target.value.trim() === ""
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={props.handleChange}
                                          helperText={
                                            props.touched.otp &&
                                            props.errors.otp
                                          }
                                          error={Boolean(
                                            props.errors.otp &&
                                              props.touched.otp
                                          )}
                                          onBlur={props.handleBlur}
                                        />
                                        <Index.Box className="add-game-btn-sec save-btn">
                                          <Index.PrimaryButton
                                            btnLabel="Verify"
                                            type="submit"
                                            className="primary-btn add-game-btn"
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </PagesIndex.Formik>
                </Index.Box>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                className="admin-tabpanel change-password-tab"
              >
                <Index.Box className="tabpanel-main">
                  <Index.Box className="edit-profile-tab game-upload-body p-0">
                    <Index.Typography
                      className="change-pass-title"
                      component="h6"
                    >
                      Password
                    </Index.Typography>
                    <Index.Typography
                      className="change-pass-sub"
                      component="h6"
                    >
                      Enter your current & new password to reset your password
                    </Index.Typography>
                    <PagesIndex.Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={
                        PagesIndex.validationSchemaChangepassword
                      }
                      onSubmit={handleChangePassword}
                    >
                      {(props) => (
                        <form onSubmit={props.handleSubmit}>
                          <Index.Box className="admin-dashboad-row edit-profile-form">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                className="display-row"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="game-title">
                                      Old Password
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.OutlinedInput
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control custom_form_control"
                                        placeholder="Enter Your Password"
                                        name="oldPassword"
                                        onBlur={props.handleBlur}
                                        value={props.values.oldPassword}
                                        onChange={props.handleChange}
                                        helperText={
                                          props.touched.oldPassword &&
                                          props.errors.oldPassword
                                        }
                                        error={Boolean(
                                          props.errors.oldPassword &&
                                            props.touched.oldPassword
                                        )}
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        endAdornment={
                                          <Index.InputAdornment
                                            position="end"
                                            className="pass_position"
                                          >
                                            <Index.IconButton
                                              className="icon_end_icon"
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showPassword ? (
                                                <Index.VisibilityOff />
                                              ) : (
                                                <Index.Visibility />
                                              )}
                                            </Index.IconButton>
                                          </Index.InputAdornment>
                                        }
                                      />
                                    </Index.Box>
                                    <Index.FormHelperText error>
                                      {props.errors.oldPassword &&
                                      props.touched.oldPassword
                                        ? props.errors.oldPassword
                                        : false}
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="game-title">
                                      New Password
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.OutlinedInput
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control custom_form_control"
                                        placeholder="Enter Your Password"
                                        name="newPassword"
                                        onBlur={props.handleBlur}
                                        value={props.values.newPassword}
                                        onChange={props.handleChange}
                                        helperText={
                                          props.touched.newPassword &&
                                          props.errors.newPassword
                                        }
                                        error={Boolean(
                                          props.errors.newPassword &&
                                            props.touched.newPassword
                                        )}
                                        type={
                                          showNewPassword ? "text" : "password"
                                        }
                                        endAdornment={
                                          <Index.InputAdornment
                                            position="end"
                                            className="pass_position"
                                          >
                                            <Index.IconButton
                                              className="icon_end_icon"
                                              aria-label="toggle password visibility"
                                              onClick={
                                                handleClickShowNewPassword
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showNewPassword ? (
                                                <Index.VisibilityOff />
                                              ) : (
                                                <Index.Visibility />
                                              )}
                                            </Index.IconButton>
                                          </Index.InputAdornment>
                                        }
                                      />
                                    </Index.Box>
                                    <Index.FormHelperText error>
                                      {props.errors.newPassword &&
                                      props.touched.newPassword
                                        ? props.errors.newPassword
                                        : false}
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="game-title">
                                      Confirm Password
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.OutlinedInput
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control custom_form_control"
                                        placeholder="Enter Your Password"
                                        name="confirmPassword"
                                        onBlur={props.handleBlur}
                                        value={props.values.confirmPassword}
                                        onChange={props.handleChange}
                                        helperText={
                                          props.touched.confirmPassword &&
                                          props.errors.confirmPassword
                                        }
                                        error={Boolean(
                                          props.errors.confirmPassword &&
                                            props.touched.confirmPassword
                                        )}
                                        type={
                                          showConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        endAdornment={
                                          <Index.InputAdornment
                                            position="end"
                                            className="pass_position"
                                          >
                                            <Index.IconButton
                                              className="icon_end_icon"
                                              aria-label="toggle password visibility"
                                              onClick={
                                                handleClickShowConfirmPassword
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showConfirmPassword ? (
                                                <Index.VisibilityOff />
                                              ) : (
                                                <Index.Visibility />
                                              )}
                                            </Index.IconButton>
                                          </Index.InputAdornment>
                                        }
                                      />
                                    </Index.Box>
                                    <Index.FormHelperText error>
                                      {props.errors.confirmPassword &&
                                      props.touched.confirmPassword
                                        ? props.errors.confirmPassword
                                        : false}
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="save-button-box">
                                    <Index.Box className="add-game-btn-sec save-btn">
                                      <Index.PrimaryButton
                                        btnLabel="Save"
                                        type="submit"
                                        className="primary-btn add-game-btn"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </form>
                      )}
                    </PagesIndex.Formik>
                  </Index.Box>
                </Index.Box>
              </TabPanel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}

export default EditProfile;
