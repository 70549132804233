import React, { useEffect, useState } from "react";
import Index from '../../../../components/Index'
import PagesIndex from "../../../PagesIndex";
import { useAccount } from "wagmi";
import { Signer, ethers, utils } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import { DugoutObj, SPNTokenObj } from "../../../../Connectivity/ContractObject";


function StakeDashboard() {
  const [TotalUser,setTotalUser] = useState(0)
  const [TotalStakeToken,setTotalStakeToken] = useState(0)
  const [TokenBalance , setTokenBalance] = useState(0)
  const [TokenAddress, setTokenAddress] = useState("")
  const [NFTAddress,setNFTAddress] = useState("")
  const signer = useEthersSigner();
  const {address,account} = useAccount();
  const [loading,setLoading]=useState(false)

  const dugout = DugoutObj(signer).then(async (res) => {
    let totaluser = await res.getTotalUser();
    let totalStakeToken = await res.getTotalStakeToken();
    let tokenAddress = await res.getTokenAddress();
    let nftAddress = await res.getNFTAddress();
    let tokenbalanceonContract = await res.getContractBalance();
    setTotalUser(totaluser.toString());
    setTotalStakeToken(utils.formatEther(totalStakeToken))
    setTokenBalance(utils.formatEther(tokenbalanceonContract))
    setTokenAddress(tokenAddress);
    setNFTAddress(nftAddress);
  }).catch((err) => {
    return err
  });

  const Details = async() =>{
    try{
      setLoading(true)
        const dugout = await DugoutObj(signer);
        let totaluser = await dugout.getTotalUser();
        setTotalUser(totaluser.toString());
        let totalStakeToken = await dugout.getTotalStakeToken();
        setTotalStakeToken(utils.formatEther(totalStakeToken))
        setLoading(false)
    }catch(error){
      setLoading(false)
    }
}
useEffect(() => {
  
if(address== undefined){
  setTotalUser(0)
  setTotalStakeToken(0)
  setNFTAddress("")
  setTokenAddress("")
  setTokenBalance(0)
}
}, [address]);
  return (
    <>
      <Index.Box className="main-dashboard">
        {/* <Index.loader></Index.loader> */}
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Dashboard
          </Index.Typography>
        </Index.Box>
        <Index.Box className="dash-card-wrapper">
          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dash-box">
                    <Index.Box className="card-icon-sec">
                      <img
                        src={PagesIndex.Svg.white_user}
                        className="card-img"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="card-content-sec">
                      <Index.Typography className="card-title" component="p">
                        Total Staked Tokens
                      </Index.Typography>
                      <Index.Typography className="card-value" component="p">
                        {/* {dashboadDataTotalUsers} */}
                        {!loading?TotalStakeToken:0}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dash-box">
                    <Index.Box className="card-icon-sec">
                      <img
                        src={PagesIndex.Svg.whiteCheck}
                        className="card-img"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="card-content-sec">
                      <Index.Typography className="card-title" component="p">
                        Number of Stakers
                      </Index.Typography>
                      <Index.Typography
                        className="card-value"
                        component="p"
                      >
                        {!loading?TotalUser:0}
                        {/* {dashboadDataActiveUsers} */}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dash-box">
                    <Index.Box className="card-icon-sec">
                      <img
                        src={PagesIndex.Svg.white_user}
                        className="card-img"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="card-content-sec">
                      <Index.Typography className="card-title" component="p">
                        Token Address
                      </Index.Typography>
                      <Index.Typography className="card-value" component="p">
                        {/* {dashboadDataTotalUsers} */}
                        {!loading ? `${TokenAddress.slice(0, 6)}...${TokenAddress.slice(-4)}` : '0x00'}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dash-box">
                    <Index.Box className="card-icon-sec">
                      <img
                        src={PagesIndex.Svg.white_user}
                        className="card-img"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="card-content-sec">
                      <Index.Typography className="card-title" component="p">
                        NFT Address
                      </Index.Typography>
                      <Index.Typography className="card-value" component="p">
                        {/* {dashboadDataTotalUsers} */}
                        {!loading ? `${NFTAddress.slice(0, 6)}...${NFTAddress.slice(-4)}` : '0x00'}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="dash-box">
                    <Index.Box className="card-icon-sec">
                      <img
                        src={PagesIndex.Svg.white_user}
                        className="card-img"
                        alt="user"
                      />
                    </Index.Box>
                    <Index.Box className="card-content-sec">
                      <Index.Typography className="card-title" component="p">
                        Available Tokens on Contract
                      </Index.Typography>
                      <Index.Typography className="card-value" component="p">
                        {/* {dashboadDataTotalUsers} */}
                        {!loading ? parseFloat(TokenBalance)?.toFixed(2) : 0}
                       
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

      </Index.Box>
    </>
  )
}

export default StakeDashboard
