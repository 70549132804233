import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import '../stack/stack.css'
import '../games/addgames.css'

function Stack() {
    return (
        <>
        <Index.Box className="main-title">
                    <Index.Typography className="head-title" component="h1">
                        Stake
                    </Index.Typography>
                </Index.Box>
            <Index.Box className="stake-token-Wrapper game-upload-wrapper add-games-main">
                
                <Index.Box className="game-upload-header">
                    Stake Tokens
                </Index.Box>
                <Index.Box className="game-upload-body">
                    <Index.List className='stake-token-ul stake-token-days'>
                        <Index.ListItem className='stake-token-li'>7 Days</Index.ListItem>
                        <Index.ListItem className='stake-token-li active'>10 Days</Index.ListItem>
                        <Index.ListItem className='stake-token-li'>32 Days</Index.ListItem>
                        <Index.ListItem className='stake-token-li'>70 Days</Index.ListItem>
                    </Index.List>
                    <Index.List className='stake-token-ul'>
                        <Index.ListItem className='stake-token-li'>Barcelona</Index.ListItem>
                        <Index.ListItem className='stake-token-li'>MetaToken</Index.ListItem>
                        <Index.ListItem className='stake-token-li'>USDT Token</Index.ListItem>
                        <Index.ListItem className='stake-token-li active'>Game Token</Index.ListItem>
                    </Index.List>
                    <Index.Box className="input-box mb-0">
                        <Index.FormHelperText className="game-title">
                            Enter Amount of Token to Stake :
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                            <Index.TextField
                                fullWidth
                                name="gameName"
                                className="form-control"

                            />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="add-game-btn-sec">
                        <Index.PrimaryButton
                            btnLabel='Stake Token'
                            className="primary-btn add-game-btn"
                            type="submit"
                        />
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default Stack
