import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import OTPInput from "react-otp-input";
import "./Otp.style.css";
function NewOtp() {
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const adminId = state?.adminId

  let initialValues = {
    email: "",
  };

  const handleOtpFormSubmit = async(values) => {

    const data = {
        otp: +values.otp,
        id: adminId,
      };
      try {
        const response = await PagesIndex.DataService.post(
          PagesIndex.Api.VARIFY_OTP,
          data
        );
        PagesIndex.toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/admin/reset-password", { state: { adminId } });
        }, 2000);
      } catch (error) {
        PagesIndex.toast.error(error?.response?.data?.message);
      }
  };

  return (
    <>
      <Index.Box className="auth-wrapper">
        <Index.Box className="auth-sec-left">
          <img src={PagesIndex.Png.authImg} className="auth-img" alt="signup" />
        </Index.Box>
        <Index.Box className="auth-sec-right">
          <PagesIndex.Formik
            onSubmit={handleOtpFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaOtp}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              handleFocus,
            }) => (
              <Index.Box
                component="form"
                onSubmit={handleSubmit}
                className="form-main-wrapper"
              >
                <img
                  src={PagesIndex.Png.login_logo}
                  className="login-logo"
                  alt="signup"
                />
                <Index.Typography className="auth-title" component="h5">
                  Welcome back!
                </Index.Typography>
                <Index.Box className="otp-input-main">
                  <Index.Box className="input-box mb-0">
                    <Index.Box className="form-group">
                      <OTPInput
                        name="otp"
                        numInputs={4}
                        inputStyle="otp-verification-input form-control"
                        containerStyle="otp-verification-input-wrap"
                        inputType="number"
                        value={values.otp}
                        onChange={(file) => setFieldValue("otp", file)}
                        error={Boolean(errors.otp)}
                        renderInput={(props) => <input {...props} />}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.PrimaryButton
                  btnLabel="Verify Otp"
                  type="submit"
                  className="primary-btn login"
                />
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NewOtp;
