import React, { useState } from "react";
import Index from "../../../container/Index";
import PagesIndex from "../../../container/PagesIndex";
import "../../../container/admin/pages/dashboardLayout/dashboard.css";

function Sidebar({ open }) {
  const location = PagesIndex.useLocation();
  const [opens, setOpens] = useState(false);
  const [active, setActive] = useState(false);
  const [gamingDropdown, setGamingDropdown] = useState(true);
  const [stackingDropdown, setStackingDropdown] = useState(false);

  const handleClickCms = () => {
    setOpens(!opens);
  };

  const navigate = PagesIndex.useNavigate();

  return (
    <>
      <Index.Box
        className={`sidebar-main ${!open ? "active" : "sidebar-none"}`}
      >
        <Index.Box className="sidebar-logo">
          <PagesIndex.Link to="/admin/dashboard">
            <Index.Box className="sidebar-logo-back">
              <img
                src={PagesIndex.Png.logoIc}
                alt="side-logo-ic"
                className="side-logo-ic"
              ></img>
              <img
                src={PagesIndex.Png.logoText}
                alt="side-logo-text"
                className="side-logo-text"
              ></img>
            </Index.Box>
          </PagesIndex.Link>
        </Index.Box>

        <Index.Box className="sidebar-drop">
          <Index.Box className="sidebar-links">
            <Index.Box className="sidebar-ul">
              <Index.Box className="sidebar-li">
                <Index.Button
                  className="cus-drop-btn"
                  onClick={() => setActive(!active)}
                >
                  <Index.Box className="btn-ic-text-main">
                    <img
                      src={
                        gamingDropdown
                          ? PagesIndex.Svg.gray_games
                          : PagesIndex.Svg.stake
                      }
                    />
                    <span>{gamingDropdown ? "Game" : "Staking"}</span>
                  </Index.Box>{" "}
                  {!active ? (
                    <img
                      src={PagesIndex.Svg.grayDown}
                      className="cus-drop-arrow"
                    />
                  ) : (
                    <img
                      src={PagesIndex.Svg.grayDown}
                      className="cus-drop-arrowup"
                    />
                  )}
                </Index.Button>
                <Index.List
                  className={active ? "cus-drop-ul active" : "cus-drop-ul"}
                >
                  <Index.ListItem
                    className={`cus-drop-li ${
                      [
                        "/admin/dashboard",
                        "/admin/user-managment",
                        "/admin/genres",
                        "/admin/games",
                        "/admin/ads-banner",
                        "/admin/new-member/list",
                        "/admin/terms-and-condition",
                        "/admin/privacy-policy",
                        "/admin/genres/add",
                        "/admin/games/add",
                        "/admin/ads-banner/add-ads-banner",
                        "/admin/new-member/list",
                      ].some((path) => location.pathname.includes(path))
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setGamingDropdown(true);
                      setStackingDropdown(false);
                      setActive(!active);
                      navigate("/admin/dashboard");
                    }}
                  >
                    <img src={PagesIndex.Svg.gray_games} /> <span>Game</span>
                  </Index.ListItem>
                  <Index.ListItem
                    className={`cus-drop-li ${
                      [
                        "/admin/stake-dashboard",
                        "/admin/admin-dashboard",
                        "/admin/lock-period",
                        "/admin/rewards-nft",
                        "/admin/add-lock-period",
                        "/admin/edit-lock-period",
                        "/admin/add-rewards-nft",
                        "/admin/edit-rewards-nft",
                      ].some((path) => location.pathname.includes(path))
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setGamingDropdown(false);
                      setStackingDropdown(true);
                      setActive(!active);
                      navigate("/admin/stake-dashboard");
                    }}
                  >
                    <img src={PagesIndex.Svg.stake} /> <span>Staking</span>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box
          className={
            gamingDropdown ? "sidebar-links" : "sidebar-links gaming-wrapper"
          }
        >
          <Index.Box className="sidebar-ul">
            <Index.Box
              className={`sidebar-li ${
                location.pathname == "/admin/dashboard" ? "active" : ""
              }`}
            >
              <PagesIndex.Link to="/admin/dashboard">
                {" "}
              
                <img src={PagesIndex.Svg.gray_dashboard} alt="" />
                <span>Dashboard</span>
              </PagesIndex.Link>
            </Index.Box>

            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/user-managment")
                  ? "active"
                  : ""
              }`}
            >
              <PagesIndex.Link to="/admin/user-managment">
                {" "}
             
                <img
                
                  src={PagesIndex.Svg.grey_user}
                  alt=""
                />
                <span>User Management</span>
              </PagesIndex.Link>
            </Index.Box>
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/genres") ? "active" : ""
              }`}
            >
              <PagesIndex.Link to="/admin/genres">
                <img
           
                  src={PagesIndex.Svg.gray_star}
                  alt=""
                />
                <span>Genres</span>
              </PagesIndex.Link>
            </Index.Box>
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/games") ? "active" : ""
              }`}
            >
              <PagesIndex.Link to="/admin/games">
                <img
                  src={PagesIndex.Svg.gray_games}
                  alt="games"
                />
                <span>Games</span>
              </PagesIndex.Link>
            </Index.Box>
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/ads-banner") ? "active" : ""
              }`}
            >
              <PagesIndex.Link to="/admin/ads-banner">
                <img src={PagesIndex.Svg.gray_ads_banners} alt="banner" />
                <span>Ads Banner</span>
              </PagesIndex.Link>
            </Index.Box>

            <Index.Box
              className={`sidebar-li ${
                location.pathname == "/admin/new-member/list" ? "active" : ""
              }`}
            >
              <PagesIndex.Link to="/admin/new-member/list">
                <img src={PagesIndex.Svg.gray_tickets} alt="" />
                <span>Ticket List</span>
              </PagesIndex.Link>
            </Index.Box>

            <Index.ListItem className="sidebar-li p-0">
              <Index.Box className="cus-cms-wrap">
                <Index.Box className="cms-text" onClick={handleClickCms}>
                  <Index.Box className="cms-text-img">
                    <img
                      src={PagesIndex.Svg.cms}
                      alt="sidebar icon"
                      className="cms-logo"
                    />
                    <span>CMS</span>
                  </Index.Box>
                  {opens ? (
                    <Index.ExpandLess className="expandless-icon" />
                  ) : (
                    <Index.ExpandMore className="expandmore-icon" />
                  )}
                </Index.Box>
                <Index.Box className="">
                  <Index.Collapse
                    in={opens}
                    timeout="auto"
                    unmountOnExit
                    className="cms-menu"
                  >
                    <Index.List component="div" disablePadding className="">
                      <Index.ListItem
                        className={`sidebar-li ${
                          location.pathname.includes(
                            "/admin/terms-and-condition"
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <PagesIndex.Link
                          to="/admin/terms-and-condition"
                          className=""
                        >
                          <img
                            src={PagesIndex.Svg.cms}
                            alt="sidebar icon"
                            className="cms-logo"
                          />
                          <span>Terms and conditions</span>
                        </PagesIndex.Link>
                      </Index.ListItem>
                      <Index.ListItem
                        className={`sidebar-li ${
                          location.pathname.includes("/admin/privacy-policy")
                            ? "active"
                            : ""
                        }`}
                      >
                        <PagesIndex.Link
                          to="/admin/privacy-policy"
                          className=" "
                        >
                          <img
                            src={PagesIndex.Svg.cms}
                            alt="sidebar icon"
                            className="cms-logo"
                          />
                          <span>Privacy policy</span>
                        </PagesIndex.Link>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Collapse>
                </Index.Box>
              </Index.Box>
            </Index.ListItem>
          </Index.Box>
        </Index.Box>
        <Index.Box
          className={
            stackingDropdown ? "sidebar-links" : "sidebar-links stack-wrapper"
          }
        >
          <Index.Box className="sidebar-ul">
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/stake-dashboard")
                  ? "active"
                  : ""
              }`}
            >
              <PagesIndex.Link to="/admin/stake-dashboard">
                <img src={PagesIndex.Svg.gray_dashboard} alt="" />
                <span>Dashboard</span>
              </PagesIndex.Link>
            </Index.Box>
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/admin-dashboard")
                  ? "active"
                  : ""
              }`}
            >
              <PagesIndex.Link to="/admin/admin-dashboard">
                <img src={PagesIndex.Svg.admin} alt="" />
                <span>Admin</span>
              </PagesIndex.Link>
            </Index.Box>
          
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/lock-period") ||
                location.pathname.includes("/admin/add-lock-period") ||
                location.pathname.includes("/admin/edit-lock-period")
                  ? "active"
                  : ""
              }`}
            >
              <PagesIndex.Link to="/admin/lock-period">
                <img src={PagesIndex.Svg.lockPeriod} alt="games" />
                <span>Lock Period</span>
              </PagesIndex.Link>
            </Index.Box>
            <Index.Box
              className={`sidebar-li ${
                location.pathname.includes("/admin/rewards-nft") ||
                location.pathname.includes("/admin/add-rewards-nft") ||
                location.pathname.includes("/admin/edit-rewards-nft")
                  ? "active"
                  : ""
              }`}
            >
              <PagesIndex.Link to="/admin/rewards-nft">
                <img src={PagesIndex.Svg.rewardsNft} alt="games" />
                <span>Rewards NFT</span>
              </PagesIndex.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
