import { createSlice } from "@reduxjs/toolkit";
import {
  loginAdmin,
  getAdminUserList,
  getGenresList,
  getBannerList,
  changePasswordProfile,
  editProfile,
  getProfile,
  editProfileWithOtp,

  getGamesList,
  getLockList,
  getRewardsList,
} from "../AddService";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    adminData: {},
    token: "",
    getAdminGameUserList: {},
    genresList: {},
    bannerList: {},
    gamesList: {},
    loading: false,
    allData: [],
    search: [],
  lockList:[],
  rewardsList:[],

    isAuthenticated: false,
    status: STATUSES.IDLE,

    getUserProfile: [],
  },
  reducers: {
    logoutAdmin: (state) => {
      state.isAuthenticated = false;
      state.adminData = {};
      state.token = "";
    },
    userLoginToken: (state, action) => {
      state.token = action?.payload;
    },

    setSearchResults: (state, action) => {
      state.search = action?.payload;
    },

    setAllData: (state, action) => {
      state.allData = action?.payload;
    },

    clearSearchResults: (state, action) => {
      state.search = [];
    },

    clearAllData: (state, action) => {
      state.allData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.adminData = action.payload?.data;
        state.isAuthenticated = true;
        state.token = action.payload?.data?.token;
      })
      .addCase(getAdminUserList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAdminUserList.fulfilled, (state, action) => {
        state.getAdminGameUserList = action.payload?.data;
      })
      .addCase(changePasswordProfile.fulfilled, (state, action) => {})
      .addCase(getAdminUserList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getGenresList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getGenresList.fulfilled, (state, action) => {
        state.genresList = action.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(getGenresList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getBannerList.fulfilled, (state, action) => {
        state.status = action.payload?.data;
      })
      .addCase(getBannerList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(getGamesList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getGamesList.fulfilled, (state, action) => {
        state.gamesList = action.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(getGamesList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      
      .addCase(getLockList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getLockList.fulfilled, (state, action) => {
        state.lockList = action.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(getLockList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })

      .addCase(getRewardsList.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getRewardsList.fulfilled, (state, action) => {
        state.rewardsList = action.payload?.data;
        state.status = STATUSES.IDLE;
      })
      .addCase(getRewardsList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })

      .addCase(editProfile.fulfilled, (state, action) => {})

      .addCase(editProfileWithOtp.fulfilled, (state, action) => {})
      .addCase(getProfile.fulfilled, (state, action) => {
        state.adminData = action.payload?.data;
      });
  },
});

export const {
  logoutAdmin,
  userLoginToken,
  setSearchResults,
  setAllData,
  clearSearchResults,
  clearAllData,
} = AdminSlice.actions;

export const selectSearchResults = (state) => state.admin.search;

export default AdminSlice.reducer;
