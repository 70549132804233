import React from 'react'
import Index from '../../../Index'

function BorderBgButton(props) {
  return (
    <>
      <Index.Box className="border-bg-btn">
        <Index.Button
          className={props.className}
          src={props.src}
          onClick={props.onClick}
          type={props.type}
        >
          <span className='btn-label'>{props.btnLabel}</span>
        </Index.Button>
      </Index.Box>
    </>
  )
}

export default BorderBgButton
