import axios from "axios";

const API_ENDPOINT = "http://35.177.56.74:3038/api/";
export const IMAGE_URL_ENDPOINT = "http://35.177.56.74:3038/uploads/";

const DataService = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: "application/json",
  },
});


export default DataService;
