import React, { useState } from "react";
import "../games/game.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getGamesList } from "../../../../redux/features/AddService";

function NewMemberList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentRows, setCurrentRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };


  const PER_PAGE = 10;

  PagesIndex.useEffect(() => {
    const count = Math.ceil(tableData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = tableData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, tableData]);

  const fetchListData = () => {
    dispatch(getGamesList(true)).then((res) => {
      if (res?.payload?.status == 200) {
        setTableData(res?.payload?.data);
      }
    });
  };
  PagesIndex.useEffect(() => {
    fetchListData();
  }, []);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSwitchToggle = (gameId) => {
    const data = {
      id: gameId,
    };

    PagesIndex.DataService.post(PagesIndex.Api.GAME_ACTIVE_DEACTIVE, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handleAddEdit = (mode, item) => {
    const editData = {
      item: mode === "Edit" ? item : null,
      mode: mode,
    };
    navigate("/admin/games/add", { state: editData });
  };

  const handleDeleteUser = (data) => {
    const payload = {
      gameIds: data,
    };

    PagesIndex.DataService.post(PagesIndex.Api.GAME_DELETE, payload)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          fetchListData();
          setSelected([]);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  const handlecheckDelete = () => {
    handleDeleteUser(selected);
  };

  const handleSelect = (itemId) => {
    if (selected?.includes(itemId)) {
      setSelected(selected?.filter((id) => id !== itemId));
    } else {
      setSelected([...selected, itemId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
    } else {
      setSelected(currentRows?.map((item) => item._id));
    }
    setSelectAll(!selectAll);
  };

  PagesIndex.useEffect(() => {
    if (selected?.length === currentRows?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selected, currentRows]);

  const [DeleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (id) => {
    const newId = [id];
    setDeleteId(newId);
    setOpenDelete(true);
  };
  const handleDeleteSingleItem = () => {
    handleDeleteUser(DeleteId);
    handleCloseDelete();
  };
  const handleCloseDelete = () => setOpenDelete(false);
  return (
    <>
      <Index.Box className="main-dashboard">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            Ticket List
          </Index.Typography>

        </Index.Box>
        <Index.Box className="page-content-box">
          <Index.Box className="barge-common-box">
            <Index.Box className="sahay-table cus-table-wrapper">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main barge-table"
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell align="left">
                       
                        <Index.Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          className="cus-table-checkbox"
                          variant="th"
                          align="center"
                        />
                      </Index.TableCell>
                      <Index.TableCell align="left">No.</Index.TableCell>
                  
                      <Index.TableCell align="left">User Name</Index.TableCell>
                      <Index.TableCell align="left">
                        Subject
                      </Index.TableCell>
                      <Index.TableCell align="left">Description</Index.TableCell>
                      <Index.TableCell align="left">Date</Index.TableCell>
                      <Index.TableCell align="left">View</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={7}>
                        <Index.Box className='no-data-img'>
                          <img src={PagesIndex.Png.noData} />
                        </Index.Box>
                      </Index.TableCell>
                    </Index.TableRow>
                  
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
          <Index.Box className="pagination-design flex-start">
            <Index.Stack spacing={2}>
              <Index.Pagination
                count={count}
                align="left"
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.whiteDelete} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={() => handleDeleteSingleItem()}
            >
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  )
}

export default NewMemberList
