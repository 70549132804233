import React, { useState } from "react";
import "../games/addgames.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getGenresList } from "../../../../redux/features/AddService";
import { IMAGE_URL_ENDPOINT } from "../../../../config/DataService";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddGames() {
  const [genresList, setGenresList] = React.useState([]);
  const dispatch = PagesIndex.useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const { state } = PagesIndex.useLocation();

  const navigate = PagesIndex.useNavigate();

  let initialValues = {
    gameName: state?.item ? state?.item?.name : "",
    genreId: state?.item ? state?.item?.genreId?._id : "",
    gameLogo: state?.item ? state?.item?.image : "",
  };


  const handleGamesEditForm = (values) => {
    const urlEncoded = new FormData();
    urlEncoded.append("name", values?.gameName);
    urlEncoded.append("genreId", values?.genreId);
    urlEncoded.append("image", values?.gameLogo);
    if (state?.item?._id) {
      urlEncoded.append("id", state?.item?._id);
    }

    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_GAME, urlEncoded)
      .then((res) => {
        if (res?.data) {
          PagesIndex.toast.success(res.data.message);
          navigate("/admin/games");
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  PagesIndex.useEffect(() => {
    dispatch(getGenresList(true)).then((res) => {
      if (res?.payload?.status == 200) {
        setGenresList(res?.payload?.data);
      }
    });
  }, []);

  PagesIndex.useEffect(() => {
    if (state?.item?.image) {
      setImageUrl(`${IMAGE_URL_ENDPOINT}${state?.item?.image}`);
    }
  }, [state?.item?.image]);

  const handleImage = (file) => {
    let url = URL.createObjectURL(file);
    setImageUrl(url);
  };

  return (
    <>
      <Index.Box className="add-games-main">
        <Index.Box className="main-title">
          <Index.Typography className="head-title" component="h1">
            {state.mode} Game
          </Index.Typography>
          <Index.Box className="header-button-box">
            <Index.Link to="/admin/games" className="cus-link-btn">
              <Index.PrimaryButton
                btnLabel="Back"
                className="primary-btn add-game-btn"
              />
            </Index.Link>
          </Index.Box>
        </Index.Box>
        <Index.Box className="game-upload-wrapper">
          <Index.Box className="game-upload-header">
            {" "}
            {state.mode} Game
          </Index.Box>

          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleGamesEditForm}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaGameAddEdit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              resetForm,
            }) => (
              <Index.Box
                className="game-upload-body"
                component="form"
                onSubmit={handleSubmit}
              >

                <Index.Box className="game-flex">
                  <Index.Box className="game-upload-logo-wrapper">
                    <Index.Typography className="game-title" component="p">
                      Game Logo
                    </Index.Typography>
                    <Index.Box className="game-upload-logo">
                      
                      <img
                        src={imageUrl ? imageUrl : PagesIndex.Svg.add}
                        className={
                          imageUrl ? "game-upload-logo" : "upload-icon"
                        }
                        alt="add"
                      />
                      <Index.Button
                        className="upload-btn-main"
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        <VisuallyHiddenInput
                          accept="image/*"
                          name="gameLogo"
                          multiple
                          type="file"
                          onChange={(e) => {
                            try {
                              if (e.target?.files?.length != 0) {
                                setFieldValue("gameLogo", e.target.files[0]);
                                handleImage(e.target.files[0]);
                              }
                            } catch (error) {
                              e.target.value = null;
                            }
                          }}
                          error={
                            errors.gameLogo && touched.gameLogo ? true : false
                          }
                          helperText={
                            errors.gameLogo && touched.gameLogo
                              ? errors.gameLogo
                              : false
                          }
                          onBlur={handleBlur}
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.gameLogo && touched.gameLogo
                        ? errors.gameLogo
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="game-upload-name">
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="game-title">
                        Game Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="GameName"
                          name="gameName"
                          className="form-control"
                          value={values?.gameName}
                          inputProps={{ maxLength: 15 }}
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.value.trim() === "") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.gameName && touched.gameName ? true : false
                          }
                          helperText={
                            errors.gameName && touched.gameName
                              ? errors.gameName
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="dropdown-box game-genre">
                      <Index.FormHelperText className="game-title">
                        Game Genre
                      </Index.FormHelperText>

                      <Index.FormControl className="form-control drop-form-control">
                        <Index.Select
                          fullWidth
                          name="genreId"
                          value={values.genreId}
                          onChange={handleChange}
                          className="dropdown-select drop-select"
                        >
                          {genresList &&
                            genresList?.map((option) => (
                              <Index.MenuItem
                                key={option._id}
                                value={option._id}
                                name={option.name}
                                className="drop-menuitem game-item"
                              >
                                {option.name}
                              </Index.MenuItem>
                            ))}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormHelperText error className="error-text">
                        {errors.genreId && touched.genreId
                          ? errors.genreId
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-game-btn-sec">
                  <Index.PrimaryButton
                    btnLabel={state.mode == "Edit" ? "Update Game" : "Add Game"}
                    className="primary-btn add-game-btn"
                    type="submit"
                  />
                </Index.Box>
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddGames;
