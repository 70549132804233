import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";

const Item = styled(Index.Paper)(({ theme }) => ({
}));

function Profile() {
  return (
    <form>
      <Index.Box className="p-15 background-ed profile-content flex-center ">
        <Index.Box className=" h-100">
          <Index.Box className="card-center">
            <Index.Box className="card-main profile-card-main">
              <Index.Box className="title-main mb-15 text-center">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Update Profile
                </Index.Typography>
              </Index.Box>
              <Index.Box className="profile-image text-center mb-20">
              
                <img
                  src={PagesIndex.Png.usericon}
                  alt="Image Preview"
                  className="profile-img mb-15"
                />
                <Index.Box className="flex-center">
                  <Index.Box className="common-button grey-button change-profile">
                    <Index.Button variant="contained" type="button">
                      Change Profile
                    </Index.Button>
                    <input
                      type="file"
                      className="change-profile-input"
                      accept="image/*"
                      name="image"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="profile-inner">
                <Index.Box className="">
                  <Index.Grid
                    container
                    columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                  >
                    <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                      <Item className="dashboard-item">
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="change-input-label"
                          >
                            First Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border mb-15">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder=""
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="firstName"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                      <Item className="dashboard-item">
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Last Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border mb-15">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder=""
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="lastName"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                      <Item className="dashboard-item">
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Email
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border mb-15">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder=""
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="email"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                      <Item className="dashboard-item">
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Mobile Number
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border mb-15">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder=""
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="mobileNumber"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Grid>
                    <Index.Grid item xs={12}>
                      <Index.Box className="set-text-area mb-20">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Address
                        </Index.Typography>
                        <Index.Box className="set-textarea-box-top">
                          <Index.TextField
                            className="set-textarea-box"
                            aria-label="empty textarea"
                            placeholder=""
                            multiline
                            name="address"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                <Index.Button variant="contained" disableRipple type="submit">
                  Update Profile
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
}

export default Profile;
