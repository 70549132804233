import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../components/admin/dashboard/Dashboard";
import ChangePassword from "../container/admin/pages/ChangePassword/ChangePassword";
import PrivayPolicy from "../container/admin/pages/cms/PrivayPolicy";
import TermsCondition from "../container/admin/pages/cms/TermsCondition";
import DashboardLayout from "../container/admin/pages/dashboardLayout/DashboardLayout";
import NewMemberList from "../container/admin/pages/newMember/NewMemberList";
import Profile from "../container/admin/pages/profile/Profile";
import NewForgotPassword from "../container/admin/auth/forgotPassword/NewForgotPassword";
import Login from "../container/admin/auth/login/Login";
import PrivateLayout from "./private/PrivateLayout";
import Genres from "../container/admin/pages/genres/Genres";
import AddGenres from "../container/admin/pages/genres/AddGenres";
import UserManagelist from "../container/admin/pages/userManagment/UserManagelist";
import Games from "../container/admin/pages/games/Games";
import AddGames from "../container/admin/pages/games/AddGames";
import AddAdsBanner from "../container/admin/pages/adsbanner/AddAdsBanner";
import AdsBanner from "../container/admin/pages/adsbanner/AdsBanner";
import NewOtp from "../container/admin/auth/otp/NewOtp";
import NewResetPassword from "../container/admin/auth/resetPassword/NewResetPassword";
import EditProfile from "../container/admin/pages/profile/EditProfile";
import PublicLayout from "./public/PublicLayout";
import Stack from "../container/admin/pages/stack/Stack";
import AdminDashboard from "../container/admin/pages/admindashboard/AdminDashboard";
import StakeDashboard from "../container/admin/pages/stakedashboard/StakeDashboard";
import LockPeriod from "../container/admin/pages/lockperiod/LockPeriod";
import AddLockPeriod from "../container/admin/pages/lockperiod/AddLockPeriod";
import EditLockPeriod from "../container/admin/pages/lockperiod/EditLockPeriod";
import RewardsNFT from "../container/admin/pages/rewardsnft/RewardsNFT";
import AddRewardsNFT from "../container/admin/pages/rewardsnft/AddRewardsNFT";
import EditRewardsNFT from "../container/admin/pages/rewardsnft/EditRewardsNFT";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Login />} />
          <Route
            path="/admin/forgot-password"
            element={<NewForgotPassword />}
          />
          <Route path="/admin/verify-otp" element={<NewOtp />} />
          <Route path="/admin/reset-password" element={<NewResetPassword />} />
        </Route>

        <Route element={<PrivateLayout />}>
          <Route path="/admin" element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="ads-banner" element={<AdsBanner />} />
            <Route
              path="ads-banner/add-ads-banner"
              element={<AddAdsBanner />}
            />
            <Route
              path="ads-banner/add-ads-banner/:id"
              element={<AddAdsBanner />}
            />
            <Route path="user-managment" element={<UserManagelist />} />
            <Route path="games" element={<Games />} />
            <Route path="games/add" element={<AddGames />} />
            <Route path="genres" element={<Genres />} />
            <Route path="genres/add" element={<AddGenres />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="update-profile" element={<Profile />} />
            <Route path="new-member">
              <Route path="list" element={<NewMemberList />} />
            </Route>
            <Route path="terms-and-condition" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivayPolicy />} />

            <Route path="stack" element={<Stack />} />
            <Route path="admin-dashboard" element={<AdminDashboard />} />
            <Route path="stake-dashboard" element={<StakeDashboard />} />
            <Route path="lock-period" element={<LockPeriod />} />
            <Route path="add-lock-period" element={<AddLockPeriod />} />
            <Route path="edit-lock-period" element={<EditLockPeriod />} />
            <Route path="rewards-nft" element={<RewardsNFT />} />
            <Route path="add-rewards-nft" element={<AddRewardsNFT />} />
            <Route path="edit-rewards-nft" element={<EditRewardsNFT />} />
          </Route>
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
