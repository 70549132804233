import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../config/Api";
import DataService from "../../config/DataService";
import { toast } from "react-toastify";

export const loginAdmin = createAsyncThunk(
  "admin/loginAdmin",
  async (data, navigate) => {
    try {
      const response = await DataService.post(Api.ADMIN_LOGIN, data);
      if (response.data.status == 200) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const forgotpasswordAdmin = createAsyncThunk(
  "admin/ForgotPassword",
  async (data, navigate) => {
    try {
      const response = await DataService.post(Api.FORGOT_PASSWORD, data);
      if (response.data.status === 201) {
        toast.success(response.data.message);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const changePasswordProfile = createAsyncThunk(
  "admin/changePasswordProfile",
  async (data) => {
    try {
      const response = await DataService.post(Api.Edit_Profile_Password, data);
      toast.success(response?.data?.message || "Something went wrong");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const editProfile = createAsyncThunk(
  "admin/editProfile",
  async (data) => {
    try {
      const response = await DataService.post(Api.UPDATE_PROFILE, data);
      toast.success(response?.data?.message || "Something went wrong");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getProfile = createAsyncThunk("admin/getProfile", async () => {
  try {
    const response = await DataService.get(Api.GET_PROFILE);

    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const editProfileWithOtp = createAsyncThunk(
  "admin/editProfileWithOtp",
  async (data) => {
    try {
      const response = await DataService.post(Api.VARIFY_OTP, data);
      toast.success(response?.data?.message || "Something went wrong");
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAdminUserList = createAsyncThunk(
  "admin/getAdminUserList",
  async () => {
    try {
      const res = await DataService.get(Api.ADMIN_GET_ALL_USER_LIST);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);



export const getGenresList = createAsyncThunk(
  "admin/getGenresList",
  async (paramData) => {
    try {
      const res = await DataService.get(
        `${Api.GET_GENRS_LIST}?list=${paramData}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const getBannerList = createAsyncThunk(
  "admin/getBannerList",
  async (paramData) => {
    try {
      const res = await DataService.get(
        `${Api.GET_BANNER_LIST}?list=${paramData}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);
export const getGamesList = createAsyncThunk(
  "admin/getGamesList",
  async (paramData) => {
    try {
      const res = await DataService.get(
        `${Api.GET_GAMES_LIST}?list=${paramData}`
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

export const getLockList = createAsyncThunk(
  "admin/lockperiod-list",
  async () => {
    try {
      const res = await DataService.get(Api.GET_LOCK_PERIOD);
      return res.data;
    } catch (error) {
    }
  }
);

export const getRewardsList = createAsyncThunk(
  "admin/lphase-detail-list",
  async () => {
    try {
      const res = await DataService.get(Api.GET_REWARD_NFT);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);